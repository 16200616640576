<template>
  <div v-if="showPayButton" class="relative h-full">
    <div id="payment-element"></div>
    <ColorButton
      :text="$t('pay')"
      @click="razorpayCheckout($event)"
      class="md:mt-8 sm:mt-4 forceWhite"
    ></ColorButton>
  </div>
  <div v-else class="fade-in">
    <p> {{ $t("connecting_to_bank") }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import loadScript from "load-script";
import { PaymentApi } from "@/service/PaymentApi";
import { GlobalErrors, RazorPayLabel } from "@/enum/constants";
import { customGlobalError } from "@/helpers/errorHelper";
import { SaleApi } from "@/service/SaleApi";
import { AuthorizeSaleRequest } from "models/authorize-sale-request";
import { CreateRazorpayOrderRequest } from "models/create-razorpay-order-request";
import ColorButton from "@/components/buttons/colorButton.vue";

export default defineComponent({
  name: "razorpayForm",
  data() {
    return {
      token: "",
      error: "",
      razorpay: {} as any,
      elements: {} as any,
      showPayButton: false,
    };
  },
  components: { ColorButton },
  props: ["data", "cardId", "user"],
  emits: ["paymentExecuted"],
  async mounted() {

    const payload:CreateRazorpayOrderRequest = {
      saleId: this.data.id,
      currencyCode: this.data.amountData.currencyCode, 
      amount: this.data.amountData.grossAmount
    }
    const response = await PaymentApi.generateRazorpayOrder(payload);
    await this.loadRazorScript();
    await this.loadRazorPayForm(response.data.orderId, response.data.clientId);
    this.razorpay.open();
  },
  methods: {
    async loadRazorScript() {
      const scriptLoaded = ref(false);
      const scriptUrl = "https://checkout.razorpay.com/v1/checkout.js";

      await new Promise((resolve, reject) => {
        loadScript(scriptUrl, (err, script: any) => {
          if (err) {
            reject(err);
          } else {
            scriptLoaded.value = true;
            resolve(script);
          }
        });
      });
    },
    async handleResponse(response:any) {
      let request: AuthorizeSaleRequest = {
        saleId: "",
        operationId: response.razorpay_payment_id
      };
      request.saleId=this.data.id;
      const saleResponse = await SaleApi.authorize(request);
      if(!saleResponse.data){
        switch (saleResponse.status) {
          case GlobalErrors.ERROR_500:
            return customGlobalError(this.$t("payment_platform_operation_cannot_be_completed"),"/");
          case GlobalErrors.ERROR_400:
            return customGlobalError(this.$t("does_no_accept_this_type_of_card"),"/");
        }
      }
      this.$emit("paymentExecuted", { success: true });
    },
    async loadRazorPayForm(orderId: string, clientId: string) {
      const data = this.data;
      const handleRes = (response:any) => { this.handleResponse (response); };
      var options = {
        "key": clientId,
        "amount": data.amountData.grossAmount,
        "currency": data.amountData.currencyCode,
        "name": RazorPayLabel.NAME,
        "description": data.tenant.name + " " + orderId,
        "image": RazorPayLabel.IMAGE_URL,
        "order_id": orderId,
        "handler": function (response:any){ handleRes(response); },
        "theme": {
          "color": RazorPayLabel.THEME_COLOR
        },
        "modal": {
            "ondismiss": () => { this.razorPayModalClosed(); }
        }
      };
      const w: any = window;
      const Razorpay = w.Razorpay;
      this.razorpay = new Razorpay(options);
      // failed response, only if we need to control outside razorpay context
      /*      
      this.razorpay.on('payment.failed', function (response:any){ 
        console.log("failed response", response)
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
        
      });
      */
    },
    razorPayModalClosed(){
      this.showPayButton = true;
    },
    async razorpayCheckout(e: any) {
      e.preventDefault();
      this.showPayButton = false;
      this.razorpay.open();
    },
  },
});

</script>
<style scoped>
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

</style>