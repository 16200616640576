import { AppsHelper } from "./appsHelper";
import { useStore } from "vuex";
import { Format } from "./formatHelper";
import { CustomerApi } from "@/service/CustomerApi";
import { Customer, CustomerPreferences, CustomerResult, Privacy, CustomerTenantDetails, LevelProviderRedirect, LinkLevelProvider } from "models";
import { AxiosResponse } from "axios";
import { PrivacyLabel, NO_LEVEL, USER_STATUS } from "@/enum/constants";
import store from "@/store";
import router from "@/router";


export default class CustomerHelper {

  store = useStore();

  static logout(store: any) {
    store.commit("setUser", "");
    localStorage.removeItem("user");
    localStorage.removeItem("t");
    localStorage.setItem('auth', USER_STATUS.LOG_OUT);
    router.push('/');
    const jsonData = {
      logout: "true",
    };
    AppsHelper.sendToIOS(window, jsonData);
  }

  static isUserLogged() {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      return true;
    }
    return false;
  }

  static loginIfNotLogged(allowClose: boolean) {
    const isUserLogged = this.isUserLogged();
    if (!isUserLogged) {
      store.commit("openLogin", { allowClose: allowClose });
    }
    return isUserLogged;
  }

  static getStoragePreferences() {
    const user = this.getUser();
    if (!Format.IsNull(user.preferences)) {
      return user.preferences;
    }
    return null;
  }

  static getUser() {
    const locaStorageUser = localStorage.getItem("user");
    if (!Format.IsNull(locaStorageUser)) {
      const user = JSON.parse(locaStorageUser!);
      return user;
    }
    return null;
  }

  static async addTenantToCustomerFavorites(id: string) {
    const response = await CustomerApi.addTenantToCustomerFavorites(id);
    return this.checkResponse(response);
  }

  static async deleteTenantFromCustomerFavorites(id: string) {
    const response = await CustomerApi.deleteTenantFromCustomerFavorites(id);
    return this.checkResponse(response);
  }

  static updateStoragePreferences(preferences: CustomerPreferences) {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      user.preferences = preferences;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  static updateStoragePrivacy(privacy: boolean) {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      user.privacy = privacy;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  static checkResponse(response: AxiosResponse) {
    if (!Format.IsNull(response.data)) {
      return response.data;
    }
    return null;
  }

  static async uploadImageForm(image: Blob) {
    const response = await CustomerApi.uploadImageForm(image);
    if (!Format.IsNull(response.data.image)) {
      this.updateStorageUser(response.data);
      return response.data.image;
    }
    return null;
  }

  static getUserImage() {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      return user.image;
    }
    return null;
  }

  static getUserFullName() {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      return user.fullName;
    }
    return null;
  }

  static getUserGender() {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      return user.gender;
    }
    return null;
  }

  static updateStorageUser(user: Customer) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static async updateFullName(fullName: string) {
    const response = await CustomerApi.updateFullName(fullName);
    if (!Format.IsNull(response.data)) {
      this.updateStorageUser(response.data);
    }

  }

  static getUserNickName() {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      return user.nickName;
    }
    return null;
  }

  static getUserId() {
    const user = this.getUser();
    if (!Format.IsNull(user)) {
      return user.id;
    }
    return null;
  }

  static async updateNickName(nickName: string) {
    const response = await CustomerApi.updateNickName(nickName);
    if (!Format.IsNull(response.data)) {
      this.updateStorageUser(response.data);
    }

  }

  static async getCustomerResults() {
    const response = await CustomerApi.getCustomerResults();
    return this.checkResponse(response);

  }

  static async findCustomerLike(start: number, size: number) {
    const response = await CustomerApi.findCustomerLike(start, size);
    return this.checkResponse(response);
  }

  static async createCustomerLike(id: string) {
    await CustomerApi.createCustomerLike(id);
  }

  static async deleteCustomerLike(id: string) {
    await CustomerApi.deleteCustomerLikeByTargetHash(id);
  }

  static async createLinkedTenant(id: string) {
    const response = await CustomerApi.createLinkedTenant(id);
    return this.checkResponse(response);
  }

  static async getPrivacy() {
    const response = await CustomerApi.getPrivacy();
    return this.checkResponse(response);
  }

  static async updatePrivacy(body: Privacy) {
    const response = await CustomerApi.updatePrivacy(body);
    return this.checkResponse(response);
  }

  static getPrivacyLabel(privacy: string): string {
    return PrivacyLabel[privacy] || privacy;
  }

  static async updateGender(gender: number) {
    const response = await CustomerApi.updateGender(gender);
    return this.checkResponse(response);
  }

  static async updateCustomerLevel(sport: number, level: number) {
    const response = await CustomerApi.updateCustomerLevel(sport, level);
    return this.checkResponse(response);
  }

  static async verifyPhone(token: string) {
    const response = await CustomerApi.verifyPhone(token);
    if (!Format.IsNull(response)) {
      return response;
    }
    return null;
  }
  static async getCustomer() {
    const response = await CustomerApi.get();
    if (!Format.IsNull(response)) {
      return response;
    }
    return null;
  }


  static async getSportLevel(sport: number, levelProvider: number) {
    
    const response = await CustomerApi.getCustomerResults();
    const data = this.checkResponse(response) as CustomerResult[];
    const sportObject = data.find((item) => item.sport?.code === sport);
    if (!sportObject) return null;

    const levelInfo = sportObject.levels!.find(l => l.levelProvider!.code === levelProvider);
    return levelInfo ? levelInfo.level : null;
  }

  static async hasLevel(sport: number, levelProvider: number) {
    const level = await this.getSportLevel(sport, levelProvider);
    if (!Format.IsNull(level) && level != NO_LEVEL) {
      return true;
    }
    return false;
  }

  static async hasGender() {
    if (!Format.IsNull(this.getUserGender())) {
      return true;
    }
    return false;
  }

  static storePreferences() {
    const userItem = localStorage.getItem("user");
    if (userItem) {
      const user = JSON.parse(userItem);
      if (user.preferences != null) {
        if (user.preferences.action != null) {
          store.commit("setPreferencesAction", user.preferences.action); //TODO: modificar el funcionamiento de actions ya que se ha complicado desde que se hizo funcionalidad tipo radiobutton
        }

        if (user.preferences.sport != null) {
          store.commit("setPreferencesSport", user.preferences.sport);
        }

        if (user.preferences?.places?.length) {
          store.commit("setPreferencesPlaces", user.preferences.places);
        }

        if (user.preferences?.tenants?.length) {
          store.commit("setPreferencesTenants", user.preferences.tenants);
        }
      }
    }
  }

  static async getPreferences() {
    return await CustomerApi.getPreferences();
  }

  static async customerIsLinked(tenantId: string): Promise<boolean | null> {
    const response = await CustomerApi.customerIsLinked(tenantId);
    const data = this.checkResponse(response) as CustomerTenantDetails;
    if (data) {
      return Boolean(data.value);
    }
    return null;
  }

  static async getProviderRedirectURL(providerId: number): Promise<LevelProviderRedirect | null> {
    const response = await CustomerApi.getProviderRedirectURL(providerId);
    return this.checkResponse(response);
  }
  
  static async linkLevelProviderAccount(body: LinkLevelProvider, id: number) {
    const response = await CustomerApi.linkLevelProviderAccount(body, id);
    return this.checkResponse(response);
  }
}