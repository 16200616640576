import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-730a05f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.css, "flex justify-center colorButton cursor-pointer"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click()))
  }, [
    _createElementVNode("span", {
      class: "text-center",
      innerHTML: _ctx.text
    }, null, 8, _hoisted_1)
  ], 2))
}