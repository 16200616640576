<template>
  <div class="fav-icon p-2 cursor-pointer"
    @click="onClick"
  >
    <SvgContainer name="shareIcon"/>
  </div>
  <Transition>
    <PopUpLight v-if="showUrl" @closed="showUrl = false">
      <div class="mt-8 OpeningOpacityText flex-col justify-evenly pb-5 OpeningResults sm:text-sm">
        <div class="flex flex-col justify-center">
          <div class="flex">{{ shareUrl }}</div>
          <div v-if="showCopied" class="flex justify-center mt-2">{{ $t("copied_exclamation")}}</div>
        </div>
      </div>
    </PopUpLight>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SvgContainer from "@/views/SvgContainer.vue";
import { isDeviceVeryOld, AppsHelper } from '@/helpers/appsHelper';
import PopUpLight from "@/components/popup/popupLight.vue";

export default defineComponent({
  name: 'ShareButton',
  components:{
    SvgContainer,
    PopUpLight
  },
  props: {
    shareUrl: {
      type: String,
      required: true
    },
    tenantName: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      showUrl: false,
      showCopied: false
    }
  },
  methods: {
    isDeviceVeryOld,
    async onClick(e: Event) {
      e.preventDefault();
      e.stopPropagation();
      if(this.isDeviceVeryOld()) {
          this.showUrl = true
         return;
      }
      const jsonData = {
        share: this.shareUrl,
      };
      try {
        AppsHelper.sendToIOS(window, jsonData);
      } catch (error) {
        console.error("Error sharing ios:", error);
      }
      if(navigator.share) {
        try {
          navigator.share({
            text: `${this.$t("im_sharing_the_link_to")} ${this.tenantName}\n\n${this.shareUrl}`,
          })
        } catch (error) {
          console.error("Error sharing:", error);
        }
      } else {
        navigator.clipboard.writeText(this.shareUrl)
        .then(() => {
          this.showUrl = true;
          this.showCopied = true;
        })
        .catch((error) => console.error("Error al copiar URL:", error));
      }
    },
  }
});
</script>
<style scoped>
  .fav-icon {
    border-radius: 50%;
    background: rgba(20, 40, 74, 0.2);
    backdrop-filter: blur(4px);
  }
</style>