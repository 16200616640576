<template>
  <div class="flex items-center mr-2">
    <img class="mr-2 icon" src="../../assets/images/calendarButton/calendar-add.svg" alt="" />
    <span @click="openGoogleCalendar" class="text-blue-500">
      <p class="xxsm:text-xs cursor-pointer">
        {{ $t("add_to_calendar") }}
      </p>
    </span>
  </div>
</template>

<script lang="ts">
import { Booking } from '@/custom-models/booking';
import { getBookingUrl } from '@/helpers/bookingHelper';
import { addMinutesToUTC, toUTCWithTimeZone, formatForGoogleCalendar} from '@/helpers/dateHelper';
import { defineComponent, PropType } from 'vue';


export default defineComponent({
  name: 'AddToCalendarButton',
  props: {
    data: {
      type: Object as PropType<Booking>, // Indica que es de tipo Booking
      required: true,
    },
  },
  methods: {
    openGoogleCalendar() {
      const title = this.$t("match_in") + " " +  this.data.tenant;
      const details = `${this.$t("share_with_friends")}: ${getBookingUrl(this.data.id!)}`;
      const location = `${this.data.cordX},${this.data.cordY}`;
      const utcDate = toUTCWithTimeZone(this.data.date, this.data.timeZone);
      const durationUtcDate = addMinutesToUTC(utcDate, this.data.duration);
      const dates = formatForGoogleCalendar(utcDate)+"/"+formatForGoogleCalendar(durationUtcDate);

      const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${details}&location=${location}&dates=${dates}`;
      window.open(googleCalendarLink, "_blank");
    },
  },
});
</script>
<style>
.icon {
  height: 24px;
}

</style>
