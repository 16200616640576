import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6045a74c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "absolute top-2 right-2 bg-black bg-opacity-50 text-white text-xs rounded p-2" }
const _hoisted_4 = {
  key: 0,
  class: "absolute bottom-2 left-2 bg-blue-600 text-white text-xs rounded p-2"
}
const _hoisted_5 = { class: "absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-sm rounded p-2" }
const _hoisted_6 = { class: "p-4 flex-grow" }
const _hoisted_7 = { class: "text-white text-lg font-bold" }
const _hoisted_8 = { class: "text-gray-300 text-sm" }
const _hoisted_9 = { class: "text-gray-400 text-xs mt-2 text-left" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "flex w-full" }
const _hoisted_12 = { class: "relative w-full input-component sm:justify-center" }
const _hoisted_13 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col", { 'activity': _ctx.isDetail === false }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.activity.image,
        alt: _ctx.activity.tenant!.name,
        class: "h-64 object-cover"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('enrollment_colon') + " " + _ctx.formatDate(new Date(_ctx.activity.registrationStartDate!), _ctx.formatDateWithLocale(_ctx.activity.amount!.culture!))) + " - " + _toDisplayString(_ctx.formatDate(new Date(_ctx.activity.registrationEndDate!), _ctx.formatDateWithLocale(_ctx.activity.amount!.culture!))), 1),
      (_ctx.activity.inscribed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('already_enrolled')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.activity.amount!.amountStr), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.activity.name), 1),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('from')) + " " + _toDisplayString(_ctx.beautifyDateWithOutTime(_ctx.activity.startDate, true, false)) + " " + _toDisplayString(_ctx.$t('to').toLowerCase()) + " " + _toDisplayString(_ctx.beautifyDateWithOutTime(_ctx.activity.endDate, true, false)), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.displayDescription), 1)
    ]),
    (_ctx.canRegister)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("input", {
                type: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signIn(_ctx.activity.id!, $event))),
                class: "search-btn sm:box-border sm:w-full sm:flex sm:flex-row sm:justify-center sm:items-center sm:p-0 sm:h-12",
                value: _ctx.$t('i_want_to_enroll')
              }, null, 8, _hoisted_13)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}