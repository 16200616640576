import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29cb6e92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "WrapperError flex justify-center flex-col items-center sm:w-full sm:p-6" }
const _hoisted_2 = { class: "text-center text-3xl not-italic font-semibold mt-4 mb-4" }
const _hoisted_3 = { class: "text-pink-400 text-center text-lg not-italic font-medium w-4/5 mb-12" }
const _hoisted_4 = { class: "buttomError cursor-pointer walletButton flex justify-center items-center" }
const _hoisted_5 = { class: "buttomError cursor-pointer walletButton flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgContainer = _resolveComponent("SvgContainer")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SvgContainer, { name: "paymentErrorLock" }),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("oh_no")) + "! ", 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1),
    (_ctx.$route.params.saleId !== 'undefined')
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: "#",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.retry()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("retry")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("back_to_home")), 1)
      ]),
      _: 1
    })
  ]))
}