import { MatchFilter } from "@/custom-models/matchFilter";
import { Sport } from "@/enum/constants";
import i18n from '@/helpers/translationHelper';

export class Format {

    static formatCurrency(amount: number, code: string, locale: string) {
        if (Format.IsNull(locale)) {
            return "";
        }
        locale = locale.replace("_", "-");
        const currencyFormatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: code
        });
        return currencyFormatter.format(amount);
    }
    /*
    static formatNumber(value: string) {
        if (value != "0" && (value == "" || value == null || value == undefined)) return "";
        if (value.toString().indexOf('.') === -1) {
            return value;
        }
        let culture = store.state.settings.Culture;
        if (culture === "" || culture === null || culture === undefined) {
            culture = "en-US";
        }
        const formatter = new Intl.NumberFormat(culture, {
            minimumFractionDigits: 2
        });
        const s = formatter.format(Number(value));

        return s;
    }
    */

    static removeNonPrintableChars(value: string) {
        if (Format.IsNull(value)) {
            return value;
        }
        return value.replace(/[^\x20-\x7E]/g, '');
    }

    /*
    static highLightText(words: any, query: string) {
        const iQuery = new RegExp(query, "ig");
        return words.toString().replace(iQuery, function (matchedTxt, a, b) {
            return ('<span class=\'post-creative-time\'>' + matchedTxt + '</span>');
        });
    }
    */
    static IsNull(value: any) {
        if (Array.isArray(value)) {
            return value.length === 0;
        }
        return value === "" || value === null || value === undefined;
    }

    static titleCase(value: string) {
        return value[0].toUpperCase() + value.slice(1).toLowerCase();
    }

    static isPadelFilter(obj: any): obj is MatchFilter {
        if (obj.type === undefined || typeof obj.type !== 'number') {
          return false;
        }
        if (obj.size === undefined || typeof obj.size !== 'number') {
          return false;
        }
        if (obj.wall === undefined || typeof obj.wall !== 'number') {
          return false;
        }
        return true;
    }

    static isTenisFilter(obj: any): obj is MatchFilter {
        if (obj.type === undefined || typeof obj.type !== 'number') {
            return false;
        }
        if (obj.size === undefined || typeof obj.size !== 'number') {
            return false;
        }
        if (obj.wall === undefined || typeof obj.wall !== 'number') {
            return false;
        }
        return true;
    }

    static isPickleballFilter(obj: any): obj is MatchFilter {
        if (obj.type === undefined || typeof obj.type !== 'number') {
            return false;
        }
        if (obj.size === undefined || typeof obj.size !== 'number') {
            return false;
        }
        if (obj.wall === undefined || typeof obj.wall !== 'number') {
            return false;
        }
        return true;
    }

    static isSoccerFilter(obj: any): obj is MatchFilter {
        if (obj.type === undefined || typeof obj.type !== 'number') {
            return false;
        }
        if (obj.size === undefined || typeof obj.size !== 'number') {
            return false;
        }
        if (obj.surface === undefined || typeof obj.surface !== 'number') {
            return false;
        }
        return true;
    }

    static isValidFilter(sport:number, attributes: any): attributes is MatchFilter{
        switch(sport) {
            case Sport.PADEL:
              if(this.isPadelFilter(attributes)){
                return true;
              }
              break;
            case Sport.TENIS:
            if(this.isTenisFilter(attributes)){
                return true;
              }
              break;
            case Sport.PICKLEBALL:
              if(this.isPickleballFilter(attributes)){
                return true;
              }
              break;
            case Sport.SOCCER:
              if(this.isSoccerFilter(attributes)){
                return true;
              }
              break;
        }
        return false;
    }

    static addTaxString(price:string, html = true): string{
        return html ? price +" + <span class='text-xs'>"+ i18n.global.t("tax") + "</span>" : price +" + "+ i18n.global.t("tax");
    }
}