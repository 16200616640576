import enUS from "./en-US.json";
import esES from "./es-ES.json";
import ca from "./ca.json";
import itIT from "./it-IT.json";

export enum Locales {
    enUS = 'en-US',
    esES = 'es-ES',
    ca = 'ca',
    itIT = 'it-IT'
}

export const LOCALES = [
    { value: Locales.enUS, caption: 'English USA' },
    { value: Locales.esES, caption: 'Español' },
    { value: Locales.ca, caption: 'Català' },
    { value: Locales.itIT, caption: 'Italiano' },
];

export const messages = {
    [Locales.ca]: ca,
    [Locales.esES]: esES,
    [Locales.enUS]: enUS,
    [Locales.itIT]: itIT,
};

export const defaultLocale = Locales.enUS;