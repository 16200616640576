<template>
  <div :class="cssClass">
    <div>{{ $t("gender") }}</div>
    <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
      <div v-if="hideIncompatibleGender && initGender != gender.FEMALE"
        @click="click($event, gender.MALE)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.MALE }"
      >
        {{ $t("male") }}
      </div>
      <div v-if="hideIncompatibleGender && initGender != gender.MALE"
        @click="click($event, gender.FEMALE)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.FEMALE }"
      >
        {{ $t("female") }}
      </div>
      <div v-if="size != PadelSize.INDIVIDUAL && sport != Sport.SOCCER"
        @click="click($event, gender.MIX)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.MIX }"
      >
        {{ $t("mixed") }}
      </div>
      <div
        @click="click($event, gender.OPEN)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: modelValue === gender.OPEN }"
      >
        {{ $t("open") }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Gender, PadelSize, Sport } from "@/enum/constants";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GenderSelector",
  components: {},
  props: {
    modelValue: Number,
    cssClass: String,
    hideIncompatibleGender: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultGender: {
      type: Number,
      required: false,
    },
    sport:{
      type: Number,
      required: true
    },
    size:{
      type: Number,
      required: true
    }
  },
  created() {
    this.initGender = this.modelValue;
  },
  mounted(){
    if(this.defaultGender){
      this.click(MouseEvent, this.defaultGender)
    }
  },
  data() {
    return {
      gender: Gender,
      PadelSize: PadelSize,
      Sport:Sport,
      initGender: undefined as number | undefined
    };
  },
  methods: {
    click(e: any, gender: any) {
      this.$emit("update:modelValue", gender);
    },
  },
});
</script>