import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-between mt-6 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("div", null, _toDisplayString(_ctx.$t("gender")), 1),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hideIncompatibleGender && _ctx.initGender != _ctx.gender.FEMALE)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click($event, _ctx.gender.MALE))),
            class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60", { selected: _ctx.modelValue === _ctx.gender.MALE }])
          }, _toDisplayString(_ctx.$t("male")), 3))
        : _createCommentVNode("", true),
      (_ctx.hideIncompatibleGender && _ctx.initGender != _ctx.gender.MALE)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.click($event, _ctx.gender.FEMALE))),
            class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60", { selected: _ctx.modelValue === _ctx.gender.FEMALE }])
          }, _toDisplayString(_ctx.$t("female")), 3))
        : _createCommentVNode("", true),
      (_ctx.size != _ctx.PadelSize.INDIVIDUAL && _ctx.sport != _ctx.Sport.SOCCER)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.click($event, _ctx.gender.MIX))),
            class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60", { selected: _ctx.modelValue === _ctx.gender.MIX }])
          }, _toDisplayString(_ctx.$t("mixed")), 3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.click($event, _ctx.gender.OPEN))),
        class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60", { selected: _ctx.modelValue === _ctx.gender.OPEN }])
      }, _toDisplayString(_ctx.$t("open")), 3)
    ])
  ], 2))
}