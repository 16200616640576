<template>
  <div
    class="flex flex-col items-center main-container"
    :style="{
      paddingTop: 'var(--safe-area-inset-top)',
      paddingBottom: 'var(--safe-area-inset-bottom)',
    }"
  >
    <div class="flex flex-wrap items-center justify-center">
      <div v-for="match in matches" 
        :key="match.id" 
        class="mr-2 ml-2 flex"
        :class="{'opacity-50 pointer-events-none': match.canJoin==false}">
        <div
          class="myMatchesClue mt-2 mb-2 cursor-pointer"
          @click="goToJoin(match)"
        >
          <div class="br16 p-2">
            <div class="flex justify-left items-center ml-6 mr-6">
              <div v-if="showSport">
                <div class="mr-2" v-if="match.sport?.code == sport.PADEL">
                  <SvgContainer :name="SportIconNames.PADEL" />
                </div>
                <div class="mr-2" v-if="match.sport?.code == sport.TENIS">
                  <SvgContainer :name="SportIconNames.TENIS" />
                </div>
                <div class="mr-2" v-if="match.sport?.code == sport.PICKLEBALL">
                  <SvgContainer :name="SportIconNames.PICKLEBALL" />
                </div>
                <div class="mr-2" v-if="match.sport?.code == sport.SOCCER">
                  <SvgContainer :name="SportIconNames.SOCCER" />
                </div>
                <div class="mr-2" v-if="match.sport?.code == sport.PADBOL">
                  <SvgContainer :name="SportIconNames.PADBOL" />
                </div>
                <div class="mr-2" v-if="match.sport?.code == sport.RACQUETBALL">
                  <SvgContainer :name="SportIconNames.RACQUETBALL" />
                </div>
                <div class="mr-2" v-if="match.sport?.code == sport.SQUASH">
                  <SvgContainer :name="SportIconNames.SQUASH" />
                </div>
              </div>
              <div v-if="match.type?.code === bookingType.MATCH" class="flex">
                <div class="mr-2"><SvgContainer name="pentaIcon" /></div>
                <div class="mr-2">
                  {{ match.minLevel?.toFixed(2) }}-{{
                    match.maxLevel?.toFixed(2)
                  }}
                </div>
                <div
                  v-if="
                    match.levelProvider &&
                    match.levelProvider.code == LevelProvider.REDPADEL
                  "
                >
                  <img :src="redPadelIcon" class="icon" />
                </div>
              </div>
              <div
                v-if="match.type?.code === bookingType.MATCH"
                class="mt-1 mb-1 text-xs custom-gradient ml-auto"
              >
                {{
                  match.competitive
                    ? addThreeDotsText($t("competitive"))
                    : addThreeDotsText($t("friendly"))
                }}
              </div>
            </div>
            <div
              v-if="match.type?.code === bookingType.MATCH"
              class="flex justify-between ml-6 mr-6"
            >
              <div class="flex items-center text-xs">
                <div>
                  {{
                    $t("gender") +
                    " " +
                    $t(getGender(Number(match.gender?.code))).toLowerCase()
                  }}
                </div>
              </div>
            </div>
            <div class="flex justify-between ml-6 mr-6">
              <div class="flex items-center text-xs">
                <div>
                  {{ getDate(match) + " " + match.duration + " " + $t("min") }}
                </div>
              </div>
            </div>
            <div class="flex justify-between ml-6 mr-6">
              <div v-if="match.tenant" class="mt-1 mb-1 text-xs">
                {{ capitalizeFirstLetter(match.tenant?.name!) }}
              </div>
            </div>
            <div v-if="!isSoccer(match)" class="flex overflow-hidden min-h-176">
              <div class="w-full flex justify-center mb-4">
                <div class="flex flex-col">
                  <MatchTeam :match="(match as Booking)" :team="0" />
                </div>
                <div class="divisor mr-4 ml-4 bg-white br16"></div>
                <div class="flex flex-col">
                  <MatchTeam :match="(match as Booking)" :team="1" />
                </div>
              </div>
            </div>
            <div class="flex text-xs items-center ml-6 mr-6">
              <span class="custom-gradient mr-2">{{ getPrice(match) }}</span>

              <div
                v-if="
                  match.status?.code === bookingStatus.BOOKED ||
                  match.status?.code === bookingStatus.FINISHED
                "
                class="ml-auto"
              >
                <!-- <span class="custom-gradient">
                  {{ addThreeDotsText($t("booked_court")) }}
                </span> -->
              </div>

              <div
                v-if="match.status?.code === bookingStatus.CANCELED"
                class="ml-auto"
              >
                <span class="error_color">
                  {{ addThreeDotsText($t("cancelled")) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="fromRouteName != 'view'"
      @click="createMatchClicked($event)"
      class="createMatch p-4 br60 cursor-pointer"
    >
      {{ $t("create_match") }}
    </div>
  </div>
  <Transition>
    <PopUpLight
      v-if="showCreateMatchPopup"
      :showOkButton="false"
      @closed="showCreateMatchPopup = false"
    >
      <div class="flex p-6 justify-around">
        <BorderButton
          @clicked="createMatchInTaykus"
          :text="$t('in_a_taykus_club')"
        ></BorderButton>
        <BorderButton
          @clicked="createMatchNotInTaykus"
          :text="$t('i_allready_have_a_court')"
        ></BorderButton>
      </div>
    </PopUpLight>
  </Transition>
  <Transition>
    <PopUpLight v-if="!hasGender" :showClose="false">
      <div class="mt-8">
        <UserGenderSelector @selectionChanged="saveGenderChanges" />
      </div>
    </PopUpLight>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { beautifyDate } from "@/helpers/dateHelper";
import {
  PlayerType,
  Category,
  BookingType,
  SearchType,
  BookingStatus,
  Sport,
  FromRouting,
  SportIconNames,
  LevelProvider,
} from "@/enum/constants";
import PopUpLight from "@/components/popup/popupLight.vue";
import BorderButton from "@/components/buttons/borderButton.vue";
import MatchTeam from "@/components/myProfile/matchTeam.vue";
import { Booking } from "models";
import SvgContainer from "@/views/SvgContainer.vue";
import { Format } from "@/helpers/formatHelper";
import CustomerHelper from "@/helpers/customerHelper";
import { capitalizeFirstLetter } from "@/helpers/dateHelper";
import { getGender } from "@/helpers/dataHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import { addThreeDotsText } from "@/helpers/dataHelper";
import UserGenderSelector from "@/components/form/userGenderSelector.vue";
import redPadelIcon from "@/assets/images/leveling/wprshortwhite.svg";

export default defineComponent({
  name: "matchAvailability",
  props: {
    activeSport: {
      type: Number,
      required: false,
    },
    matches: {
      //type: Array as () => Booking[],//any mientras no tenga el model
      type: Array as () => any[],
      default: () => [],
      required: true,
    },
    fromRouteName: {
      type: String,
      required: false,
    },
    canBeCancelled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["matchSelected", "createMatch", "bookingCancelled"],
  components: {
    PopUpLight,
    BorderButton,
    MatchTeam,
    SvgContainer,
    UserGenderSelector,
  },
  data() {
    return {
      playerType: PlayerType,
      bookingStatus: BookingStatus,
      bookingType: BookingType,
      showCreateMatchPopup: false,
      isUserLogged: false,
      sport: Sport,
      showSport: true,
      selectedSport: this.activeSport as number,
      SportIconNames: SportIconNames,
      redPadelIcon: redPadelIcon,
      LevelProvider: LevelProvider,
      hasGender: true,
    };
  },
  created() {
    if (
      this.fromRouteName == FromRouting.SEARCH_RESULTS ||
      this.fromRouteName == FromRouting.BOOKING
    ) {
      this.showSport = false;
    }
  },
  mounted() {
    this.fixIphoneHeight();
  },
  methods: {
    addThreeDotsText,
    capitalizeFirstLetter,
    getGender,
    handleBookingCancelled() {
      this.$emit("bookingCancelled");
    },
    getDate(match: any) {
      return beautifyDate(match.date, true, false);
    },
    getPrice(match: Booking) {
      if (this.fromRouteName == "view") {
        // Show customer amount paid if route name is matches lists in customer profile
        if (match.amountPaid) {
            return match.amountPaid.amountStr;
        }
        return "";
      }
      // Show place amount in matches availability
      if (match.amount) {
        return match.amount.amountStr;
      }
      return "";
    },
    goToJoin(match: Booking) {
      this.$emit("matchSelected", match);
    },
    async saveGenderChanges(gender: number) {
      const user = await CustomerHelper.updateGender(gender);
      if (!Format.IsNull(user)) {
        CustomerHelper.updateStorageUser(user);
      }
      this.hasGender = true;
    },
    async createMatchClicked(e: any) {
      e.preventDefault();
      e.stopPropagation();

      if (this.$route.query && this.$route.query.sport) {
        this.selectedSport = Number(this.$route.query.sport);
      }
      if (this.activeSport) {
        //priorizamos al deportes enviado ya que se dijo de no trabajar con el deporte de la query, revisar y ya se ha quitado en todos sitios y eliminar el de query.sport
        this.selectedSport = this.activeSport;
      }

      this.isUserLogged = CustomerHelper.loginIfNotLogged(true);
      if (!this.isUserLogged) return;

      if (this.fromRouteName === FromRouting.BOOKING) {
        const query: any = {
          tenantIds: [this.$route.query.id],
          name: this.$route.query.name,
          date: this.$route.query.date,
          sport: this.selectedSport,
          category: Category.BOOKING,
          radius: 3,
          searchType: SearchType.FULL,
          flexibleTime: true,
          createMatchMode: true,
          createInClub: true,
        };
        var createMatchLoc = {
          name: "search-results",
          query: query,
        };
        return this.$router.push(createMatchLoc);
      }
      this.showCreateMatchPopup = true;
    },
    createMatchInTaykus(e: any) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("createMatch");
      this.showCreateMatchPopup = false;
    },
    createMatchNotInTaykus() {
      return this.$router.push("/create-match" + window.location.search);
    },
    isSoccer(match: Booking) {
      if (match === null) {
        return false;
      }
      return match.sport?.code === Sport.SOCCER;
    },
    fixIphoneHeight() {
      if (AppsHelper.isIOS()) {
        const newMatchBtn = document.querySelector(".createMatch");
        if (newMatchBtn) {
          newMatchBtn.classList.add("iOS-bottom-btn");
        }
      }
    },
  },
});
</script>

<style scoped>
.icon {
  width: 23px;
  height: 23px;
}
.error_color {
  color: #f472b6;
}
.createMatch {
  background: rgb(9, 9, 121);
  background: linear-gradient(90deg, var(--color1) 35%, var(--color2) 100%);
  text-align: center;
  width: 80%;
  position: fixed;
  left: 50%;
  bottom: calc(env(safe-area-inset-bottom) + 12px);
  transform: translate(-50%, 0px);
  min-width: 200px;
}

.myMatchesClue {
  min-width: 300px; /* Set the smallest min-width as the default */
  background: var(--background-color);
  /* box-shadow: 0px 0px 15px -4px #3c7eeb; */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2), 0px 0px 8px -4px #3c7eeb;
  border-radius: 20px;
}

@media (min-width: 767px) {
  .createMatch {
    width: 400px;
  }
  .myMatchesClue {
    min-width: 411px;
  }
}
.myMatchesClue .divisor {
  width: 3px;
}
</style>
