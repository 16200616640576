
<template>
  <div
    :class="css"
    class="flex justify-center colorButton cursor-pointer"
    @click="click()"
  >
    <span class="text-center" v-html="text"></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "colorButton",
  components: {},
  emits: ["clicked"],
  props: ["text", "css"],
  data() {
    return {};
  },
  methods: {
    click() {
      this.$emit("clicked");
    },
  },
});
</script>
<style scoped>
.colorButton {
  border-radius: 32px;
  padding: 14px 0;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 767px) {
  .colorButton {
    border-radius: 32px;
    width: 327px;
    padding: 14px 0;
    background-image: linear-gradient(to right, var(--color1), var(--color2));
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

