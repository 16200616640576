<template>
  <div>
    <div class="flex items-center justify-center">
      <img
        v-if="showImage"
        class="mb-6 xxsm:hidden image"
        :src="imageUrl"
        alt=""
      />
    </div>
    <div
      v-if="parentName == 'checkoutView'"
      class="custom-gradient text-center text-2xl not-italic font-semibold md:pb-2 xxsm:pb-2 md:mb-2 xxsm:mb-2 xxsm:text-base"
    >
      <span v-if="booking.type?.code == bookingType.MATCH">{{
        $t("match_details")
      }}</span>
      <span v-else>{{ $t("booking_details") }}</span>
    </div>
    <div
      class="containerPaymentText flex justify-evenly sm:text-xs sm:text-center"
    >
      <div
        class="walletButton pl-6 pr-6 relative cursor-pointer sm:m-1"
        v-if="showChat"
        @click="goToChat()"
      >
        {{ $t("chat") }}
      </div>
      <div
        class="walletButton pl-6 pr-6 relative cursor-pointer sm:m-1"
        v-if="openFacility"
        @click="openFacilityURL()"
      >
        {{ $t("open_facility") }}
      </div>
    </div>
    <span class="text-center">
      <CancelBooking
        v-if="booking.canBeCancelled && !isCheckoutSuccessPage()"
        :bookingId="bookingId"
        @bookingCancelled="handleBookingCancelled()"
      />
    </span>
    <div class="containerPaymentText flex justify-between sm:justify-start">
      <div class="flex items-center mr-2">
        <img class="mr-2" src="../../assets/images/calendar.svg" alt="" />
        <p class="xxsm:hidden">{{ $t("date_colon") }}</p>
      </div>
      <p class="playerSubtitle xxsm:text-xs">
        {{ getDateStr() }} - {{ booking.duration }} {{ $t("min") }}
      </p>
    </div>
    <div class="containerPaymentText flex justify-between sm:justify-start">
      <div class="flex items-center mr-2">
        <div class="flex items-center">
          <div class="mr-2" v-if="booking.sport == sport.PADEL">
            <SvgContainer name="homeModalPadelIcon" fillColor="grey" />
          </div>
          <div class="mr-2" v-if="booking.sport == sport.TENIS">
            <SvgContainer name="homeModalTenisIcon" fillColor="grey" />
          </div>
          <div class="mr-2" v-if="booking.sport == sport.PICKLEBALL">
            <SvgContainer name="homeModalPickleIcon" fillColor="grey" />
          </div>
          <div class="mr-2" v-if="booking.sport == sport.SOCCER">
            <SvgContainer name="homeModalSoccerIcon" fillColor="grey" />
          </div>
          <div class="mr-2" v-if="booking.sport == sport.PADBOL">
            <SvgContainer name="homeModalPadbolIcon" fillColor="grey" />
          </div>
          <div class="mr-2" v-if="booking.sport == sport.RACQUETBALL">
            <SvgContainer name="homeModalRacquetballIcon" fillColor="grey" />
          </div>
          <div class="mr-2" v-if="booking.sport == sport.SQUASH">
            <SvgContainer name="homeModalSquashIcon" fillColor="grey" />
          </div>
          
        </div>
        <p class="xxsm:hidden">{{ $t("field_colon") }}</p>
      </div>
      <p v-if="fieldName" class="playerSubtitle xxsm:text-xs">
        {{ fieldName }}
      </p>
      <p v-else class="playerSubtitle xxsm:text-xxs">
        <ResourceLabel
          :sport="booking.sport"
          :data="dataAttributes"
          :showName="showResourceName"
          :emitLabel="true"
          @emitLabel="handleEmitLabel"
        />
      </p>
    </div>
    <div
      v-if="booking.type?.code == bookingType.MATCH"
      class="containerPaymentText flex justify-between sm:justify-start"
    >
      <div class="flex items-center mr-2">
        <div class="flex items-center">
          <div class="mr-2">
            <SvgContainer name="registerFullNameIcon" fillColor="grey" />
          </div>
        </div>
        <p class="xxsm:hidden">{{ $t("gender_colon") }}</p>
      </div>
      <p class="playerSubtitle xxsm:text-xs">{{ $t(matchGender) }}</p>
    </div>
    <div
      v-if="booking.type?.code == bookingType.MATCH"
      class="containerPaymentText flex justify-between sm:justify-start"
    >
      <div class="flex items-center mr-2">
        <div class="flex items-center">
          <div class="mr-2">
            <SvgContainer name="pentaIconBorder" fillColor="grey" />
          </div>
        </div>
        <p class="xxsm:hidden">{{ $t("level") }}</p>
      </div>
      <div class="flex flex-row justify-end items-center">
        <div class="flex ml-2">
          <p class="playerSubtitle xxsm:text-xs">{{ booking.minLevel + " - " + booking.maxLevel }}</p>
        </div>
        <div v-if="levelProvider == LevelProvider.REDPADEL" class="flex ml-2">
          <img :src="redPadelIcon" class="icon"/>
        </div>
        <div class="flex mr-2 ml-2">
          |
        </div>
        <div v-if="booking.competitive || booking.matchRequest?.competitive" class="flex xxsm:text-xs">
          {{ $t("competitive") }}
        </div>
        <div v-else class="flex xxsm:text-xs">
          {{ $t("friendly") }}
        </div>
      </div>
    </div>
    <div class="containerPaymentText flex justify-between sm:justify-start">
      <div class="flex items-center mr-2">
        <img class="mr-2" src="../../assets/images/Location.svg" alt="" />
        <p class="xxsm:hidden">{{ $t("location_colon") }}</p>
      </div>
      <router-link to="#" @click="goToMaps()" class="text-blue-500">
        <p class="xxsm:text-xs">
          {{ capitalizeFirstLetter(booking.tenant) }}
        </p>
      </router-link>
    </div>
    <div
      v-if="formattedPrice"
      class="containerPaymentText flex justify-between sm:justify-start"
    >
      <div class="flex items-center mr-2">
        <div class="flex items-center">
          <div class="mr-2">
            <SvgContainer name="dollarIcon" />
          </div>
        </div>
        <p class="xxsm:hidden">{{ $t("total_amount_colon") }}</p>
      </div>
      <p class="playerSubtitle xxsm:text-xs" v-html="formattedPrice"></p>
    </div>
    <AddToCalendarButton v-if="showAddCalendar" class="containerPaymentText"
      :data="booking"
    />
    <div>
    </div>
    <div class="containerPaymentText flex justify-between" v-if="showShare">
      <div
        class="text-center tooltip w-full xxsm:text-xs"
        v-if="!isDeviceVeryOld()"
      >
        <div class="relative mb-2 cursor-pointer w-full">
          <input
            readonly
            class="relative lastInput w-full items-center pr-4"
            type="text"
            :placeholder="getTextShare()"
            id="bookingUrl"
            @click="copyToClipBoard()"
          />
          <div @click="copyToClipBoard()" class="inputImage">
            <div v-if="isInShare()">
              <SvgContainer name="shareIcon"/>
            </div>
            <div v-else>
              <SvgContainer name="copyIcon"/>
            </div>
          </div>
        </div>
        <span class="tooltiptext-hidden" :class="{ tooltiptext: copied }">{{
          $t("copied_exclamation")
        }}</span>
      </div>
    </div>
  </div>
  <Transition>
    <PopUpLight
      v-if="showPopupChat"
      @closed="closeChat()"
      :customClass="'width-lg'"
    >
      <TaykusChat :bookingId="bookingId" />
    </PopUpLight>
  </Transition>
</template>
  
<script type="ts">
import { beautifyDate, capitalizeFirstLetter, beautifyDateWithOutTime } from "@/helpers/dateHelper";
import { MAP_URL, DEFAULT_TENANT_IMAGE, BookingStatus, Sport, BookingType, LevelProvider, PlayerType } from "@/enum/constants";
import { Booking } from "@/custom-models/booking";
import { AppsHelper, isDeviceVeryOld } from "@/helpers/appsHelper";
import TaykusChat from "@/components/chat/TaykusChat.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import { Format } from "@/helpers/formatHelper";
import { getGender } from "@/helpers/dataHelper";
import { BookingHelper, getBookingUrl } from "@/helpers/bookingHelper";
import SvgContainer from "@/views/SvgContainer.vue";
import CancelBooking from "@/components/form/cancelBooking.vue";
import ResourceLabel from "@/components/booking/resourceLabel.vue";
import redPadelIcon from "@/assets/images/leveling/wprshortwhite.svg";
import AddToCalendarButton from "@/components/booking/addToCalendarButton.vue"

export default {
  name: "bookingDetail",
  emits: ["bookingCancelled"],
  props: {
    showImage: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Booking,
    },
    showChat: {
      type: Boolean,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showOpenFacility: {
      type: Boolean,
      default: true,
    },
    class: {
      type: String,
    },
    showResourceName: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAddCalendar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PopUpLight,
    TaykusChat,
    SvgContainer,
    CancelBooking,
    ResourceLabel,
    AddToCalendarButton
  },
  computed: {
    parentName() {
      return this.$parent.$options.name;
    },
  },
  data() {
    return {
      showPopupChat: false,
      bookingId: String,
      imageUrl: DEFAULT_TENANT_IMAGE,
      bookingStatus: BookingStatus,
      copied: false,
      sport: Sport,
      bookingType: BookingType,
      matchGender: "",
      formattedPrice: null,
      bookingBlocks: null,
      dataAttributes: null,
      fieldName: null,
      openFacility: false,
      LevelProvider: LevelProvider,
      redPadelIcon: redPadelIcon,
      levelProvider: null,
      resourceLabel:"",
      formattedPriceMsg: null
    };
  },
  created() {
    if (!Format.IsNull(this.booking.field)) {
      this.fieldName = this.booking.field;
    }
    if (this.showOpenFacility && !Format.IsNull(this.booking.openFacilityUrl)) {
      this.openFacility = true;
    }
    if (!Format.IsNull(this.booking.bookingBlocks)) {
      this.bookingBlocks = this.booking.bookingBlocks;
    }
    if (this.booking.attributes) {
      this.dataAttributes = this.booking;
    }
    if (this.bookingBlocks && this.bookingBlocks.attributes) {
      this.dataAttributes = this.bookingBlocks;
    }
    this.bookingId = this.booking.id;
    if (!Format.IsNull(this.booking.imageUrl)) {
      this.imageUrl = this.booking.imageUrl;
    }
    if(this.booking.levelProvider){
      this.levelProvider = this.booking.levelProvider;
    }
    if(this.booking.levelProvider && this.booking.levelProvider.code){
      this.levelProvider = this.booking.levelProvider.code;
    }
    if(this.booking.type.code && this.booking.type.code === BookingType.MATCH){
      this.isMatch = true;
    }
    this.matchGender = getGender(this.booking.gender?.code);
    if (this.bookingId && this.booking.price) {
      this.formattedPrice = this.booking.price.amountStr;
      this.formattedPriceMsg = this.booking.price.amountStr;
      if(this.booking.price.withoutTaxes){
        this.formattedPrice = Format.addTaxString(this.booking.price.amountStr);
        this.formattedPriceMsg = Format.addTaxString(this.booking.price.amountStr, false);
      }
    }
    this.fixIphoneHeight();
  },
  mounted() {
    if (this.$route.query.chat === "true") {
      this.goToChat();
    }
  },

  methods: {
    capitalizeFirstLetter,
    isDeviceVeryOld,
    getBookingUrl,
    handleEmitLabel(resourceLabel){
      this.resourceLabel = resourceLabel
    },
    isInShare(){
      return navigator.share !== undefined
    },
    getTextShare() {
      return this.isInShare()
        ? this.$t("share_with_friends")
        : getBookingUrl(this.booking.id);
    },
    formatShareDetails() {
      // Tenant
      const header = `📢 ${this.$t("match_in")} ${this.booking.tenant} \n`;
      // Fecha y duración
      const dateTime = beautifyDateWithOutTime(this.booking.date, true, false)
      const duration = `${this.booking.duration} ${this.$t("min")}`;
      // Detalles del nivel
      let levelDetail = "" ;
      if(this.isMatch){
        const gender = this.matchGender;
        let competitive = this.$t("friendly") 
        if(this.booking.matchRequest?.competitive || this.booking.competitive){
          competitive = this.$t("competitive") 
        }
        levelDetail = `📈 ${this.booking.minLevel} - ${this.booking.maxLevel} | ${competitive} | ${capitalizeFirstLetter(gender)}\n`;
      }
      // Atributos
      let name = "";
      if(this.booking.name){
        name = this.booking.name + " | ";
      }
      const attributes = `🏷️ ${name}${this.resourceLabel}`;

      // Precio y dirección
      const price = `💰 ${this.formattedPriceMsg}`;
      //const location = `📍 Dirección sólo en caso de haberla\n`;
      const location = `\n`;

      // Jugadores
      let players = "";
      let lastTeam = 0
      if(this.booking.players.length > 0) {
        players = this.booking.players
        .filter((player) => player.type.code == PlayerType.CUSTOMER)
        .sort((a, b) => a.team - b.team)
        .flatMap((player) => {
          const result = [];
          if (lastTeam !== player.team) {
            // Añadir "VS" si cambia de equipo
            result.push("VS");
          }
          if(player.level){
            result.push(`✅ ${player.name} (${player.level})`);
          }else{
            result.push(`✅ ${player.name}`);
          }
          lastTeam = player.team;
          return result;
        })
        .join("\n");
      }
      return `${header}🗓️ ${dateTime}, ${duration}\n${levelDetail}${attributes}\n${price}\n${location}${players}`;
    },
    openFacilityURL() {
      const jsonData = {
        openFacility: this.booking.openFacilityUrl,
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
      window.open(this.booking.openFacilityUrl, "_blank");
    },
    isCheckoutSuccessPage() {
      return this.$route.path.includes("checkout-ok");
    },
    handleBookingCancelled() {
      this.$emit("bookingCancelled");
    },
    copyToClipBoard() {
      if(navigator.share) {
        const details = this.formatShareDetails();
        try {
          navigator.share({
            text: details,
            url: getBookingUrl(this.booking.id),
          })
        } catch (error) {
          console.error("Error sharing:", error);
        }
        return;
      } 
      this.copied = BookingHelper.copyBoookingUrlToClipBoard(this.booking.id);
    },
    getDateStr() {
      return beautifyDate(this.booking.date, true, false);
    },
    getMampsUrl() {
      const cordX = this.booking.cordX;
      const cordY = this.booking.cordY;
      return MAP_URL + cordX + "," + cordY;
    },
    goToMaps() {
      window.open(this.getMampsUrl());
    },
    closeChat() {
      this.showPopupChat = false;
    },
    goToChat() {
      const jsonData = {
        groupId: this.bookingId,
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
      this.showPopupChat = true;
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const wrapperCheckout = document.querySelector(".wrapperCheckout");
        if (wrapperCheckout) {
          wrapperCheckout.classList.add("pb-150");
        }
      }
    },
  },
};
</script>
<style scoped>
.icon {
  width: 26px;
  height: 26px;
}
/* Tooltip text */
.tooltip .tooltiptext {
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip .tooltiptext-hidden {
  visibility: hidden;
  opacity: 0;
}
.tooltip .tooltiptext {
  visibility: visible !important;
  opacity: 1 !important;
}
.inputImage {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translate(-20px, -50%);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lastInput {
  border-radius: 24px;
  opacity: 0.800000011920929;
  background: linear-gradient(
      270deg,
      rgba(12, 31, 50, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    rgba(255, 255, 255, 0.02);
  box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.15) inset;
  backdrop-filter: blur(16px);
  padding: 12px 40px 12px 24px;
  gap: 12px;
}

.image {
  max-width: 548px !important;
  max-height: 424px !important;
  border-radius: 16px;
}
.containerPaymentText {
  padding: 21px 0;
}

@media (max-width: 767px) {
  .containerPaymentText {
    padding: 8px 0;
  }
}
</style>