<template>
  <div class="pb-8">
    <HeaderView></HeaderView>
    <Transition>
      <PopUp
        v-if="showPaymentOk"
        :showOkButton="false"
        :showCancelButton="true"
        @closed="$router.push('/')"
      >
        <div class="md:p-4 md:pt-6">
          <div class="relative h-full">
            <div class="flex justify-center content-center">
              <SvgContainer name="roundedOkIcon"/>                
            </div>
            <span class="text-center mt-6 mb-6 text-2xl">
              {{ $t("congratulations_exclamation") }}
            </span>
            <div :class="{ 'mt-8': booking.type?.code != BookingType.MATCH }">
              <div>
                <ActivityDetail
                  v-if="booking.type?.code == BookingType.ACTIVITY"
                  :activity="booking"
                  :showImage="false"
                  :showShare="false"
                >
                </ActivityDetail>
                <BookingDetail
                  v-if="booking.duration !== undefined && (booking.type?.code == BookingType.MATCH || booking.type?.code == BookingType.BOOKING)"
                  :booking="booking"
                  :showImage="false"
                  :showResourceName="true"
                ></BookingDetail>
              </div>

              <div
                class="flex justify-center mt-6 cursor-pointer"
                @click="toBooking()"
              >
                <span class="custom-gradient font-bold cursor-pointer mr-2">
                  {{ $t("view_booking") }}
                </span>
                <SvgContainer name="shareIcon"/>
              </div>
            </div>
          </div>
        </div>
      </PopUp>
    </Transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import HeaderView from "@/components/header/header.vue";
import PopUp from "@/components/popup/popup.vue";
import BookingDetail from "@/components/booking/bookingDetail.vue";
import ActivityDetail from "@/components/booking/activityDetail.vue";
import SvgContainer from "@/views/SvgContainer.vue";
import LocalStorage from "@/helpers/localStorage";
import { customGlobalError } from "@/helpers/errorHelper";
import { BookingType } from "@/enum/constants";


export default defineComponent({
  name: "checkoutOkView",
  components: {
    HeaderView,
    PopUp,
    BookingDetail,
    ActivityDetail,
    SvgContainer
  },
  data() {
    return {
      saleId: "" as string | string[],
      booking: {} as any,
      showPaymentOk: false,
      BookingType: BookingType,
      resourceLabel: "" as string,
      isMatch: false as boolean
    };
  },
  mounted() {
    document.body.style.overflowY = "scroll";
    document.body.style.background = "none";
    setTimeout(() => (this.showPaymentOk = true), 100);

    this.saleId = this.$route.params.saleId;
    if (this.saleId === undefined || this.saleId === null) {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }

    const strB = LocalStorage.get(this.saleId.toString());
    if (strB === "" || strB === null) {
      customGlobalError(this.$t("purchase_process_expired"), "/");
      return;
    }
    const b: any = JSON.parse(strB);
    this.booking = b.value;
  },
  methods: {
    getBookingUrl() {
      if(this.booking && this.booking.type.code == BookingType.ACTIVITY){
        return document.location.origin + "/activity?a=" + this.booking.id;
      }
      return document.location.origin + "/booking-join?b=" + this.booking.id;
    },
    async toBooking() {
      window.open(this.getBookingUrl());
    }
  },
});
</script>
<style scoped>
.wrapperBottomWallet {
  bottom: 30px;
}
.successfulCheck {
  margin: 0 auto;
}
</style>