import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8bf8f1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pb-8" }
const _hoisted_2 = { class: "md:p-4 md:pt-6" }
const _hoisted_3 = { class: "relative h-full" }
const _hoisted_4 = { class: "flex justify-center content-center" }
const _hoisted_5 = { class: "text-center mt-6 mb-6 text-2xl" }
const _hoisted_6 = { class: "custom-gradient font-bold cursor-pointer mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_SvgContainer = _resolveComponent("SvgContainer")!
  const _component_ActivityDetail = _resolveComponent("ActivityDetail")!
  const _component_BookingDetail = _resolveComponent("BookingDetail")!
  const _component_PopUp = _resolveComponent("PopUp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderView),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showPaymentOk)
          ? (_openBlock(), _createBlock(_component_PopUp, {
              key: 0,
              showOkButton: false,
              showCancelButton: true,
              onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/')))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_SvgContainer, { name: "roundedOkIcon" })
                    ]),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("congratulations_exclamation")), 1),
                    _createElementVNode("div", {
                      class: _normalizeClass({ 'mt-8': _ctx.booking.type?.code != _ctx.BookingType.MATCH })
                    }, [
                      _createElementVNode("div", null, [
                        (_ctx.booking.type?.code == _ctx.BookingType.ACTIVITY)
                          ? (_openBlock(), _createBlock(_component_ActivityDetail, {
                              key: 0,
                              activity: _ctx.booking,
                              showImage: false,
                              showShare: false
                            }, null, 8, ["activity"]))
                          : _createCommentVNode("", true),
                        (_ctx.booking.duration !== undefined && (_ctx.booking.type?.code == _ctx.BookingType.MATCH || _ctx.booking.type?.code == _ctx.BookingType.BOOKING))
                          ? (_openBlock(), _createBlock(_component_BookingDetail, {
                              key: 1,
                              booking: _ctx.booking,
                              showImage: false,
                              showResourceName: true
                            }, null, 8, ["booking"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", {
                        class: "flex justify-center mt-6 cursor-pointer",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toBooking()))
                      }, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("view_booking")), 1),
                        _createVNode(_component_SvgContainer, { name: "shareIcon" })
                      ])
                    ], 2)
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}