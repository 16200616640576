import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import './assets/main.css';
import vue3GoogleLogin from 'vue3-google-login';
import clickOutside from './directives/click-outside';
import GlobalError from './components/globalError/GlobalError.vue';
import { CometChat } from '@cometchat-pro/chat';
import { USER_STATUS } from './enum/constants';
import * as Sentry from "@sentry/vue";
import loadTranslator from './helpers/translationHelper';

console.error = (message?: any) => {
  throw new Error(message);
};

const i18n = loadTranslator;

//Mapping to translate navigator.language options to flatpickr languagesm get 'en' as default because defaultLocale is 'en-US'
const storeLocale = navigator.language.includes('es') ? 'es' 
: (navigator.language.includes('en') ? 'en' 
    : (navigator.language.includes('it') ? 'it' 
        : navigator.language)
  )
//TODO INFO: Para cuando tengamos que cambiar el idioma dependiendo del usuario hacemos overwrite del mapping de storeLocale
store.commit('setI18nLocale', storeLocale);



const appID = "2434008c7a89d230", //TODO buscar la manera de ocultar estos datos
  region = "EU",
  appSetting: CometChat.AppSettings = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();
CometChat.init(appID, appSetting).then(
  (initialized: boolean) => {
    // Do nothing when initialized
  }, (error: CometChat.CometChatException) => {
    console.log("Initialization failed with error:", error);
  }
);


const app = createApp(App)
  .component('GlobalError', GlobalError)
  .directive("click-outside", clickOutside)
  .use(store).use(router).use(i18n).use(vue3GoogleLogin, {
    clientId: '577167699684-2ot1otvnfubaa88h9ll4nahj7ekk1jmb.apps.googleusercontent.com'
  });

const environment = process.env.VUE_APP_SENTRY_ENVIRONMENT;

Sentry.init({
  app,
  dsn: "https://6ba2593b14c6b5ea6ab556b3a530af35@o4508919027466240.ingest.de.sentry.io/4508919031857232",
  environment: environment,
  beforeSend(event) {
    const ignoredStatusCodes = [401, 403, 409];

    const isIgnoredXHR = event.breadcrumbs?.some(
      (breadcrumb) => 
        breadcrumb.category === "xhr" &&
        ignoredStatusCodes.includes(breadcrumb.data?.status_code)
    );
    // ignore automatic error (XHR) with status 401, 403 and 409
    if (isIgnoredXHR && event.level === "error") {
      return null;
    }
    // send captureMessage("Gobal Error", { level: "info", "warning", etc })
    return event;
  },
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: environment === "local"
    ? ["localhost:4000"]
    : environment === "staging"
      ? ["taykusdemo.com", /^https:\/\/tudominio\.com\/api/]
      : ["taykus.com", /^https:\/\/tudominio\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");

window.addEventListener('storage', (event) => {
  if (event.key === 'auth') {
    if (event.newValue === USER_STATUS.LOG_OUT) {
      handleLogout();
    }
  }
});
function handleLogout() {
  window.location.href = '/';
}
  

