<template>
  <div class="w-full" v-click-outside="clickOutSide" id="searchContainer">
    <div class="search-container">
      <img
        class="seach-icon absolute"
        src="../../assets/images/magnifying-glass.svg"
        alt=""
      />
      <input
        ref="searchInput"
        class="reference-seach w-full"
        v-model="searchQuery"
        :placeholder="placeholder"
        @keyup="handleInput"
        @focus="handleFocus"
      />
      <img
        class="clear-icon absolute"
        src="../../assets/images/clear.svg"
        alt=""
        @click="clearSearch()"
      />
      <ul v-if="showResults || showButtons" class="wrapper-list-select flex-wrap mt-1">
        <div v-if="showButtons" class="text-center flex">
          <li
            v-if="
              showFavortieTenants == true &&
              searchTenantEnabled &&
              allowFavorites
            "
          >
            <div
              class="walletButton p-1 text-center cursor-pointer text-sm mt-1"
              @click="sendMyFavorites()"
            >
              {{ $t("my_favorite_clubs") }}
            </div>
          </li>
          <li v-if="allowNearMe == true" >
            <div
              class="walletButton p-1 text-center cursor-pointer text-sm mt-1"
              @click="sendNearToMe()"
            >
              {{ $t("near_me") }}
            </div>
          </li>
        </div>
        <h1 v-if="filteredTenants.length && searchTenantEnabled">
          {{ $t("clubs") }}
        </h1>
        <li
          class="relative"
          v-for="club in filteredTenants"
          :key="club.id"
          @click="selectItem(club)"
        >
          <div class="autoCompleteContainer">
            <img
              class="absolute gps-icon"
              src="../../assets/images/gps.svg"
              alt=""
            />
            <p style="cursor: pointer">
              {{ capitalizeFirstLetter(club.name) }}
            </p>
          </div>
        </li>
        <h1 v-if="filteredLocations.length">
          {{ $t("based_in") }}
        </h1>
        <li
          class="relative"
          v-for="loc in filteredLocations"
          :key="loc.id"
          @click="selectItem(loc)"
        >
          <div class="autoCompleteContainer">
            <img
              class="absolute gps-icon"
              src="../../assets/images/gps.svg"
              alt=""
            />
            <p style="cursor: pointer">{{ loc.name }}</p>
          </div>
        </li>
      </ul>
    </div>
    <ul v-if="!onlyOneSelect && !isSearching" class="wrapper-list-select flex-wrap mt-3">
      <li v-for="(value, index) in selectedValues" :key="index">
        <button class="m-1 items-center relative" @click="removeItem(value)">
          {{ value.name }}
          <span class="close-list-element absolute text-xs">x</span>
        </button>
      </li>
    </ul>
    <!-- <p v-if="error" class="error-message absolute">{{ error }}</p> //TODO de momento no mostramos el errores --> 
  </div>
  <Transition>
    <PopUpLight v-if="enableLocationMsg" @closed="enableLocationMsg = ''">
      <div class="mt-8">
        {{ enableLocationMsg }}
      </div>
    </PopUpLight>
  </Transition>
</template>
<script>
import { TenantApi } from "@/service/TenantApi";
import { Format } from "@/helpers/formatHelper";
import store from "../../store";
import { AppsHelper } from "@/helpers/appsHelper";
import { capitalizeFirstLetter } from "@/helpers/dateHelper";
import PopUpLight from "@/components/popup/popupLight.vue";

export default {
  name: "InputWhere",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    addLocations: {
      type: Boolean,
      required: false,
    },
    onlyOneSelect: {
      type: Boolean,
      required: false,
    },
    allowNearMe: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowFavorites: {
      type: Boolean,
      required: false,
      default: true,
    },
    searchTenantEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    externalSearchQuery: {
      type: String,
      required: false,
    },
    
  },
  created() {
    // Define the function in the global scope
    window.getCoordsFromIOS = (JSONCoords) => {
      const coord = JSON.parse(JSONCoords);
      this.sendNearSearch(coord.cordY, coord.cordX);
    };
  },
  data() {
    return {
      showResults: false,
      searchQuery: "",
      filteredTenants: [],
      filteredLocations: [],
      selectedValues: [],
      showButtons: false,
      showFavortieTenants: false,
      error: "",
      enableLocationMsg: "",
      Format: Format,
      isSearching: false,
      searchTimer: null,
    };
  },
  emits: [
    "tenantsLocationsSelected",
    "sendNearSearch",
    "sendFavoritesSearch",
    "sendTenantToBooking",
  ],
  components: {
    PopUpLight,
  },
  mounted() {
    this.searchQuery = "";
    this.checkLoggedUseraAndPreferences();
    if (
      !Format.IsNull(this.$route.query.id) &&
      !Format.IsNull(this.$route.query.name)
    ) {
      this.searchQuery = this.$route.query.name;
      const tenant = {
        id: this.$route.query.id,
        name: this.$route.query.name,
      };
      this.$emit("sendTenantToBooking", tenant, true);
      return;
    }

    if (
      !Format.IsNull(this.$route.query.cordX) &&
      !Format.IsNull(this.$route.query.cordY)
    ) {
      this.searchQuery = this.$route.query.locName;
      const place = {
        cordX: this.$route.query.cordX,
        cordY: this.$route.query.cordY,
      };

      this.$emit("tenantsLocationsSelected", place);
      return;
    }

    if (
      store.state.preferences.places !== null &&
      store.state.preferences.places.length !== 0 &&
      !this.externalSearchQuery
    ) {
      const places = store.state.preferences.places;
      this.searchQuery = this.$t("near_me");
      this.$emit("tenantsLocationsSelected", places);
      return;
    }

    if (
      store.state.preferences !== undefined &&
      store.state.preferences.tenants !== null &&
      store.state.preferences.tenants.length !== 0
    ) {
      this.selectedValues = store.state.preferences.tenants;
    }
  },
  methods: {
    capitalizeFirstLetter,
    clickOutSide() {
      this.filteredTenants = [];
      this.filteredLocations = [];
      this.showButtons = false;
      this.showResults = false;
    },
    clearSearch() {
      this.searchQuery = "";
      this.clickOutSide();
      this.restoreScroll();
      this.$refs.searchInput.focus();
    },
    mapArray(arrayToMap) {
      const mappedArray = arrayToMap.map((selected) => {
        const selectedValue = {
          tenantId: selected.id,
          name: selected.name,
        };
        selectedValue.cordX = selected.cordX;
        selectedValue.cordY = selected.cordY;

        return selectedValue;
      });
      return mappedArray;
    },

    async checkLoggedUseraAndPreferences(autocomplete = true) {
      if(autocomplete){
        const userItem = localStorage.getItem("user");
        if (userItem != null) {
          const user = JSON.parse(localStorage.getItem("user"));
          if (user.preferences != null)
            if (user.preferences.tenants != null) {
              if (user.preferences.tenants.length != 0) {
                this.showFavortieTenants = true;
              }
            }
        }
        if(this.externalSearchQuery){
          this.searchQuery = this.externalSearchQuery;
          const externalSearchTypes = {
            favorite: this.$t("favorite_clubs"),
            near: this.$t("near_me"),
          };
          if (this.searchQuery === externalSearchTypes.favorite) {
            this.sendMyFavorites(true);
          } else if (this.searchQuery === externalSearchTypes.near) {
            this.sendNearToMe(true);
          }
          await this.fetchData(false);
          let location = this.filteredTenants.find(tenant => tenant.name === this.externalSearchQuery);
          if(!location){
            location = this.filteredLocations.find(location => location.name === this.externalSearchQuery);
          }
          if(location){
            this.selectItem(location, true);
          }
        }
      }
    },

    async sendNearToMe(waitForButton = false) {
      const jsonData = {
        closeToMyLocation: "true",
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
      this.askForGeolocationPermission(waitForButton);
    },
    async sendMyFavorites(waitForButton = false) {
      this.resetLocations();
      const locations = [
        { name: this.$t("favorite_clubs"), cordX: null, cordY: null },
      ];
      store.commit("setPreferencesPlaces", locations);
      store.commit("setPreferencesTenants", []);
      this.$emit("sendFavoritesSearch", locations, waitForButton);
    },
    sendNearSearch(cordY, cordX, waitForButton) {
      const locations = [
        { name: this.$t("near_me"), cordX: cordX, cordY: cordY },
      ];
      store.commit("setPreferencesPlaces", locations);
      store.commit("setPreferencesTenants", []);
      this.$emit("sendNearSearch", locations, waitForButton);
    },
    async handleFocus() {
      await this.fetchData();
      this.scrollToTop();
      this.checkLoggedUseraAndPreferences(false);
      if(this.onlyOneSelect){
        this.showButtons = true;
      }
    },

    async fetchData(showResults = true) {
      if (this.searchQuery.length > 2) {
        this.error = "";
        const response = await TenantApi.findByNameAndPlaceContaining(this.searchQuery);
        if(Format.IsNull(response.data.tenants) && Format.IsNull(response.data.places) || (Format.IsNull(response.data.tenants) && !this.onlyOneSelect)){
          this.showResults = false;
          return;
        }
        this.showResults = showResults;
        if (this.searchTenantEnabled) {
          const tenants = response.data.tenants;
          this.filteredTenants = tenants;
        }
        if (this.addLocations) {
          const places = response.data.places;
          this.filteredLocations = places.map((place) => ({
            id: place.placeId,
            name: place.description,
          }));
        }
      }
    },

    isValid() {
      return this.error === "";
    },

    handleInput() {
      this.searchQuery = this.$refs.searchInput.value;
      this.error = this.$t("search_min_chars");
      clearTimeout(this.searchTimer)
      this.isSearching = true;
      this.searchTimer = setTimeout(() => {
        this.fetchData();
      }, 200);
    },
    async selectItem(item, waitForButton = false) {
      this.restoreScroll();
      this.isSearching = false;
      this.error = "";
      const existingItem = this.selectedValues.find(
        (value) => value.id === item.id
      );

      if (!this.onlyOneSelect && existingItem) {
        //Item already exist in list values
        return;
      }

      if (this.onlyOneSelect) {
        this.searchQuery = capitalizeFirstLetter(item.name);
        this.selectedValues = [];
        const foundLocation = this.filteredLocations.find(
          (location) => location.id === item.id
        );

        this.filteredLocations = [];
        if (foundLocation != null || foundLocation != undefined) {
          const location = await this.getCoordLocation(foundLocation);
          if (location !== null && location !== undefined) {
            item.cordX = location.cordX;
            item.cordY = location.cordY;
          }
        } else {
          this.filteredTenants = [];
          this.$emit("sendTenantToBooking", item, waitForButton);
          return;
        }
      }
      this.selectedValues.push(item);
      const mappedSelectedValues = this.mapArray(this.selectedValues);
      this.filteredTenants = [];
      this.showButtons = false;
      this.showResults = false;

      store.commit("setPreferencesTenants", mappedSelectedValues);
      this.$emit("tenantsLocationsSelected", mappedSelectedValues);
    },
    scrollToTop() {
      if (AppsHelper.isSafariOrIosWebView()) {
        return;
      }

      const targetElement = document.getElementById("searchContainer");
      if (targetElement) {
        targetElement.classList.add("search-mobile");
      }
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
    restoreScroll() {
      if (AppsHelper.isSafariOrIosWebView()) {
        return;
      }

      const targetElement = document.getElementById("searchContainer");
      if (targetElement) {
        targetElement.classList.remove("search-mobile");
      }
      setTimeout(() => {
        document.body.scrollTop = 0;
      }, 300);
    },
    removeItem(value) {
      const index = this.selectedValues.indexOf(value);
      if (index !== -1) {
        this.selectedValues.splice(index, 1);
      }
    },

    resetLocations() {
      this.filteredTenants = [];
      this.filteredLocations = [];
      store.commit("setPreferencesPlaces", []);
      store.commit("setPreferencesTenants", []);
    },

    async getCoordLocation(foundLocation) {
      this.resetLocations();
      const response = await TenantApi.getPlaceDetails(foundLocation.id);
      let myLocation = response.data;
      store.commit("setPreferencesPlaces", [myLocation]);
      store.commit("setPreferencesTenants", []);
      return myLocation;
    },
    async askForGeolocationPermission(waitForButton) {
      //TODO poner en un dataHelper
      if ("geolocation" in navigator) {
        try {
          //const position = await this.getCurrentPosition();
          const position = { coords: {latitude:1, longitude:2}}
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          this.sendNearSearch(longitude, latitude, waitForButton);
        } catch (error) {
          if (error.code === error.PERMISSION_DENIED) {
            this.enableLocationMsg = this.$t("geolocation_locked");
          } else {
            this.enableLocationMsg = this.$t("geolocation_browser_error");
          }
        }
      } else {
        this.enableLocationMsg = this.$t("geolocation_browser_error");
      }
    },
    async getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          (error) => {
            reject(error);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
        );
      });
    }

  },
};
</script>
<style scoped>
h1 {
  text-align: left;
  /* color: #e6e6e6; */
  margin: 8px 0px 9px 0px;
  font-size: 14px;
}
p {
  text-align: left;
  /* color: #e6e6e6; */
  margin: 8px 0px 9px 20px;
  font-size: 14px;
  opacity: 0.8;
}
.gps-icon {
  margin-top: 8px;
  margin-bottom: 9px;
}
.autoCompleteContainer {
  display: flex;
  align-items: center;
}
.text-xs {
  font-size: 1rem;
  line-height: 1.5rem;
}
.reference-seach {
  /* opacity: 0.8; */
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(16px);
  height: 44px;
  padding-left: 46px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;

  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}
.button {
  /* color: #999999; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  border-radius: 24px;
  background: linear-gradient(
    139.51deg,
    rgba(26, 39, 60, 0.73) 11.77%,
    rgba(28, 43, 69, 0) 86.2%
  );
  padding: 12px 28px;
  cursor: pointer;
  margin-left: 20px;
}
.wrapper-list-select{
  overflow-y: auto;
  max-height: 560px;
  min-height: 40px;
}

</style>