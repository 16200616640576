<template>
    <div class="mt-8">
        <div class="mt-5 text-center text-base">
        {{ $t("do_you_have_an_account_at_this_club") }}
        </div>
        <div class="OpeningOpacityText mt-5 text-center font-normal text-sm">
        {{ $t("link_your_account_and_get_club_benefits") }}
        </div>
        <div class="flex justify-center">
            <div
                class="user-icon mt-5 text-center search-btn cursor-pointer py-4 w-[279px]"
                @click="openClubLink"
            >
                {{ $t("link_account") }}
            </div>
        </div>
    </div>
    <Transition>
        <PopUpLight
        v-if="showPopLinkAccount"
        @closed="closePopupLink"
        >
            <div class="mt-8">
                <iframe title="LinkAccount" class="iframe" frameborder="0" :src="urlLink"></iframe>
            </div>
        </PopUpLight>
    </Transition>

</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { Format } from "@/helpers/formatHelper";
import CustomerHelper from "@/helpers/customerHelper";
import PopUpLight from "../popup/popupLight.vue";
import { Booking } from 'models';
import { customGlobalError } from "@/helpers/errorHelper";

export default defineComponent({
    name: "LinkPrivate",
    emits: ["linkClosed"],
    props: {
        booking: {
            type: Object as () => Booking,
            required: true
        },
    },
    data() {
        return {
            showPopLinkAccount: false as boolean,
            urlLink: "" as string | undefined,
        };
    },
    async mounted() {
        window.addEventListener("message", this.message, false);
    },
    beforeUnmount() {
        window.removeEventListener("message", this.message, false);
    },
    components: {
        PopUpLight
    },
    methods: {
        async openClubLink(e: MouseEvent) {
            e.preventDefault();
            e.stopPropagation();
            if (!this.booking.tenant) {
                customGlobalError(this.$t("oops_technical_problems_try_again_in_a_few_minutes"));
                return;
            }
            if (!this.booking.tenant.id) {
                customGlobalError(this.$t("oops_technical_problems_try_again_in_a_few_minutes"));
                return;
            }
            const linkedData = await CustomerHelper.createLinkedTenant(
                this.booking.tenant.id
            );
            this.urlLink = linkedData.value;
            this.showPopLinkAccount = true;
        },
        closePopupLink(messageOk = false) {
            this.showPopLinkAccount = false;
            this.$emit("linkClosed", messageOk);
        },
        message(e: any) {
            if (e.data === true) {
                this.closePopupLink(true);
                return;
            }
        },
    },
    
});
</script>
<style scoped>
.iframe {
  width: 100%;
  height: 600px;
  overflow: hidden;
  border-radius: 20px;
}

.iframe::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in WebKit browsers */
}
</style> 