import { createI18n } from 'vue-i18n';
import { defaultLocale, messages } from '../locales/localization';

const i18n = createI18n({
    legacy: false,
    locale: navigator.language.includes('es') ? 'es-ES' 
    : (navigator.language.includes('en') ? 'en-US' 
        : (navigator.language.includes('it') ? 'it-IT' 
            : navigator.language)
      ),
    fallbackLocale: defaultLocale,
    messages,
    fallbackWarn: false,
    silentTranslationWarn: true,
});

export function loadTranslator() {
    return i18n;
}

export default i18n;