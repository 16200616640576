<template>
  <div>
    <Header></Header>
    <div class="fixed top-16 left-[10%] z-[100]"><HistoryBack :forceUrl="'/'" /></div>
    <div><p class="custom-gradient text-center mb-6 mt-4 text-xl" v-if="createMatchMode">{{ $t("select_hour_for_your_match") }}</p></div>
    <div class="search-reference">
      <div class="flex justify-center w-full p-4">
        <div class="flex flex-col">
          <div class="flex">
            <div class="search-container relative w-full input-component">
              <img
                class="seach-icon absolute"
                src="@/assets/images/magnifying-glass.svg"
                alt=""
              />
              <input
                class="input-rounded w-72"
                ref="fakeSearchBtn"
                type="text"
                :placeholder="$t('search_your_clubs')"
                @focus="openSearch()"
                v-model="fakeMainSearch"
              />
            </div>
          </div>
          <div class="flex justify-between mt-2">
            <div v-if="!createMatchMode">
              <CategorySelector  
                :optionDefault="selectedCategory"
                @optionSelected="selectCategory"
              />
            </div>
            <div>
              <SportButtonSelector @sportSelected="selectSport" />
            </div>
            <div v-if="sportHasFilters()">
              <div class="cursor-pointer" @click="openFilter">
                <SvgContainer name="searchResultFilterIcon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="search-elements search-elements-container flex flex-wrap items-center justify-center m-auto"
        v-if="selectedCategory === category.BOOKING"
      >
        <div v-for="element in bookingsDataSource" :key="element.tenant.id">
          <BookingsAvailability
            :gallery="element.tenant.gallery"
            :name="element.tenant.name"
            :bestPrice="element.bestPriceLocalized.amountStr"
            :location="element.tenant.address"
            :hours="element.times"
            :distance="element.distance"
            :tenantId="element.tenant.id"
            :cordX="element.tenant.cordX"
            :cordY="element.tenant.cordY"
            :private="element.tenant.private"
            :showTax="element.bestPriceLocalized.withoutTaxes"
            @hourSelected="hourClicked"
            @tenantSelected="tenantClicked"
          />
        </div>
      </div>
      <div
        v-if="noElementsMsg !== ''"
        class="mt-8 mb-8 flex flex-wrap items-center justify-center text-center"
      >
        {{ noElementsMsg }}
      </div>
      <div v-if="selectedCategory === category.MATCH" class="search-elements flex flex-wrap items-center justify-center m-auto">
        <MatchAvailability
          :matches="matchesDataSource"
          fromRouteName="search-results"
          @matchSelected="matchSelected"
          @createMatch="createMatch"
        />
      </div>
      <div v-if="selectedCategory === category.ACTIVITY" class="search-elements flex flex-wrap items-center justify-center m-auto ">
        <ActivityAvailability
          :activities="activitiesDataSource"
        />
      </div>
    </div>
    <Transition>
      <PopUpLight v-if="showMainSearch" @closed="closeSearch()">
        <div class="mt-12 sm:mt-4">
          <MainSearchForm
            :isPopup="true"
            :searchTenantEnabled="createMatchMode === false"
            @closePopup="handleClosePopup"
            @locationSelected="handleLocationSelected"
            @tenantSelected="handleTenantSelected"
            :key="mainSearchFormPopUpKey"
            :createInClub="createInClub"
          />
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showFilter" @closed="closeFilter()">
        <MultipleFilterSelector
          @sendFilter="handleFilter"
          :filter="filter"
          :sport="sport"
          :category="selectedCategory"
        />
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight
        v-if="createMatchMode && showGenderLevel && !isLoading"
        @closed="closeGenderLevel()"
        :customClass="'width-sm'"
        :showClose="false"
      >
        <UpdateUser
          :customSport="sport"
          @closeGenderLevel="closeGenderLevel()"
        />
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showHasGender"
        :showClose="true"
        @closed="initRedPadelLevel"
      >
        <div class="mt-8">
          <UserGenderSelector @selectionChanged="saveGenderChanges" />
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showIframe" 
        @closed="showIframe= false"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          <iframe class="iframe" title="redPadel login" frameborder="0" :src="iframeSrc"></iframe>
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showRedpadelError" 
        @closed="showRedpadelError= false"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          {{ $t("oops_technical_problems_try_again_in_a_few_minutes") }}
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight
        v-if="showPopLinkPrivate"
        @closed="showPopLinkPrivate = false"
      >
        <div class="mt-5 flex justify-evenly sm:text-sm">
          <span> {{ $t("the_club_is_private") }} </span>
        </div>
        <div class="flex justify-evenly sm:text-sm">
          <LinkPrivate
            :booking="linkTenantInfo"
            @linkClosed="closePrivateLink"
          />
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { defineComponent } from "vue";
import { checkURLAvailabilityFormat, getSearchDataToSend, getDataToBooking, getSportLabel} from "@/helpers/dataHelper";
import { advance30min, beautifyDate, beautifyDateWithOutTime } from "@/helpers/dateHelper";
import Header from "@/components/header/header.vue";
import SvgContainer from "./SvgContainer.vue";
import SportButtonSelector from "@/components/form/sportButtonSelector.vue";
import MainSearchForm from "@/components/search/mainSearchForm.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import BookingsAvailability from "@/components/search/bookingAvailability.vue";
import MatchAvailability from "@/components/search/matchAvailability.vue";
import ActivityAvailability from "@/components/search/activityAvailability.vue";
import MultipleFilterSelector from "@/components/search/multipleFilterSelector.vue";
import { BookingHelper } from "@/helpers/bookingHelper";
import {Category, SearchType, Sport, BookingViewTabs, NO_LEVEL, Purpose} from "@/enum/constants";
import { customGlobalError } from "@/helpers/errorHelper";
import { Format } from "@/helpers/formatHelper";
import { formatDate } from "@/helpers/dateHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import UpdateUser from "@/components/match/updateUser.vue";
import CustomerHelper from "@/helpers/customerHelper";
import HistoryBack from "@/components/form/historyBack.vue";
import CategorySelector from "@/components/booking/categorySelector.vue"
import { LevelProvider } from '@/enum/constants';
import MatchHelper from "@/helpers/matchtHelper";
import UserGenderSelector from "@/components/form/userGenderSelector.vue";
import { useRefreshWatcher } from "@/helpers/dataHelper";
import LinkPrivate from "@/components/form/linkPrivate.vue";

export default defineComponent({
  components: {
    SvgContainer,
    Header,
    SportButtonSelector,
    MainSearchForm,
    PopUpLight,
    BookingsAvailability,
    MatchAvailability,
    ActivityAvailability,
    MultipleFilterSelector,
    UpdateUser,
    UserGenderSelector,
    HistoryBack,
    CategorySelector,
    LinkPrivate
  },
  data() {
    return {
      category: Category,
      showMainSearch: false,
      showFilter: false,
      selectedCategory: 0,
      responseSearchItems: null,
      fakeMainSearch: "",
      dataToSend: {},
      mainSearchFormPopUpKey: 0,
      noElementsMsg: "",
      isLoading: true,
      filter: {},
      bookingsDataSource: [],
      matchesDataSource: [],
      activitiesDataSource:[],
      createMatchMode: false,
      sport: Sport.PADEL,
      showGenderLevel: false,
      isUserLogged: false,
      level: null,
      createInClub: false,
      levelProvider: LevelProvider.PANDORA,
      hasGender: null,
      showIframe: false,
      showRedpadelError: false,
      showHasGender: false,
      iframeSrc:"",
      defaultLevelProviders: null,
      canCreateMatch: false,
      createMatchQuery: null,
      showPopLinkPrivate: false,
      linkTenantInfo: null
    };
  },
  created() {
    this.store = useStore();
    this.selectedCategory = Number(this.$route.query.category);
    if (this.$route.query.createMatchMode === "true") {
      this.createMatchMode = true;
    }
    if (this.$route.query.createInClub === "true") {
      this.createInClub = true;
    }
    this.setUpDataFromUrl();
  },
  async mounted() {
    useRefreshWatcher(() => { this.handleRefresh(); });// This function will execute when the 'isRefreshed' state changes. It is passed this way because it is passed by reference.
    if (window.location.href.includes("search-results")) {
      document.body.style.overflowY = "scroll";
    }
    this.setInitialFakeSearch();
    this.$watch("$route", (to, from) => {
      // Perform actions or checks based on the URL change
      this.sendObjectFromUrl(to.fullPath);
    });
    this.sendObjectFromUrl(window.location.href);
    this.isLoading = false;
  },
  methods: {
    async linkRedPadelAccount(linkLevelProvider){
      try {
        await CustomerHelper.linkLevelProviderAccount(linkLevelProvider, LevelProvider.REDPADEL);
        this.sendMatch(this.createMatchQuery);
      } catch (error) {
        this.showRedpadelError = true;
      }
    },
    async redPadelListener(event) {
      window.removeEventListener("message", this.redPadelListener);
      const resultData = event.data;
      this.showIframe = false;
      if(resultData.status){
        const linkLevelProvider = {
          accountId: resultData.user_id,
        };
        await this.linkRedPadelAccount(linkLevelProvider);
      }else{
        this.showRedpadelError = true;
      }
    },
    async saveGenderChanges(gender) {
      const user = await CustomerHelper.updateGender(gender);
      if (user) {
        CustomerHelper.updateStorageUser(user);
        this.hasGender = true;
        this.initRedPadelLevel();
      }
      this.showHasGender = false;
    },
    async initGenderLevel() {
      this.hasGender = await CustomerHelper.hasGender();
      if(!this.hasGender && this.levelProvider == LevelProvider.REDPADEL){
        this.showHasGender = true;
        return false;
      }
      this.isLoading = true;
      const hasLevel = await CustomerHelper.hasLevel(this.sport, this.levelProvider);
      this.isLoading = false;
      if((hasLevel)) {
        return true;
      }
      this.levelProvider === LevelProvider.PANDORA ? this.showGenderLevel = true : await this.initRedPadelLevel();
      return false;
    },
    async initRedPadelLevel(){
      this.showHasGender = false;
      if(this.hasGender){
        const response = await CustomerHelper.getProviderRedirectURL(LevelProvider.REDPADEL);
        if(response){
          this.iframeSrc = response.redirectURL;
          this.showIframe = true;
          window.addEventListener("message", this.redPadelListener);
        }
      }
    },
    setInitialFakeSearch() {
      if (
        this.$route.query.date != null ||
        this.$route.query.date != undefined
      ) {
        let urlDate = new Date(this.$route.query.date);
        const now = new Date();

        if (urlDate < now) {
          const advancedDate = advance30min(now);
          this.fakeMainSearch = beautifyDate(advance30min(now), true, false);
          this.store.commit("setfullDateTime", formatDate(advancedDate));
          return;
        }
        this.store.commit("setfullDateTime", formatDate(urlDate));
        if(this.createInClub){
          this.fakeMainSearch = beautifyDateWithOutTime(urlDate, true, false);  
          return;
        }
        this.fakeMainSearch = beautifyDate(urlDate, true, false);
        return;
      }

      if (
        this.store.state.preferences.tenants != null &&
        this.store.state.preferences.tenants.length !== 0
      ) {
        this.fakeMainSearch = this.store.state.preferences.tenants[0].name;
      }
      if (
        this.store.state.preferences.places != null &&
        this.store.state.preferences.places.length !== 0
      ) {
        this.fakeMainSearch = this.store.state.preferences.places[0].name;
      }
    },
    async selectCategory(option) {
      this.selectedCategory = Number(option);
      this.store.commit("setPreferencesAction", option);
      await this.loadData();
    },
    async selectSport(sport) {
      if (this.sport === sport) {
        return;
      }
      this.sport = sport;
      this.store.commit("setPreferencesSport", sport);
      const currentQuery = { ...this.$route.query };
      currentQuery.sport = this.sport;
      this.$router.push({ query: currentQuery });
    },
    handleClosePopup() {
      this.closeSearch();
    },
    handleRefresh() {
      const userItem = localStorage.getItem("user");
      if (userItem != null) {
        this.mainSearchFormPopUpKey++;
      }
      this.loadData();
    },
    handleFilter(filter) {
      this.closeFilter(); //TODO: create buton to clear filter
      this.filter = filter;
      //in case of match now don't filter and leave function
      if (this.selectedCategory === Category.MATCH) {
        return;
      }
      //first filter, return only tenants that any filter match
      this.bookingsDataSource = this.responseSearchItems.filter((item) => {
        return item.filters.some((filterItem) => {
          const filterTypeMatch =
            filter.type.length === 0 ||
            filter.type.includes(filterItem.padelType);
          const filterWallMatch =
            filter.subType.length === 0 ||
            filter.subType.includes(filterItem.padelWall);
          const filterSizeMatch =
            filter.size.length === 0 ||
            filter.size.includes(filterItem.padelSize);

          return filterTypeMatch && filterWallMatch && filterSizeMatch;
        });
      });
      //second filter, keep only the filters that match in the filtered tenants, to calculate the lowest best price of mached filters
      this.bookingsDataSource = this.bookingsDataSource.map((item) => {
        const matchingFilters = item.filters.filter(
          (filterItem) =>
            (filter.type.length === 0 ||
              filter.type.includes(filterItem.padelType)) &&
            (filter.subType.length === 0 ||
              filter.subType.includes(filterItem.padelWall)) &&
            (filter.size.length === 0 ||
              filter.size.includes(filterItem.padelSize))
        );
        // Return a new object with the updated filters
        return {
          ...item,
          filters: matchingFilters,
        };
      });
      this.bookingsDataSource = this.mergedFilter(this.bookingsDataSource);
    },
    mergedFilter(filteredTenants) {
      const mergedFilters = filteredTenants.map((filteredTenant) => {
        let times = filteredTenant.filters.reduce((merged, filter) => {
          filter.times.forEach((time) => {
            if (!merged.some(item => item.value === time.value)) {
              merged.push(time);
            }
          });
          return merged;
        }, []);
        times.sort((a, b) => a.value.localeCompare(b.value));

        const smallestPrice = Math.min(
          ...filteredTenant.filters.map((filter) =>
            parseFloat(filter.bestPriceLocalized.amount)
          )
        );
        const matchingFilter = filteredTenant.filters.find(
          (filter) =>
            parseFloat(filter.bestPriceLocalized.amount) === smallestPrice
        );

        return {
          tenant: filteredTenant.tenant,
          filters: filteredTenant.filters,
          bestPriceLocalized:{amountStr: matchingFilter.bestPriceLocalized.amountStr},
          distance: filteredTenant.distance,
          times,
        };
      });
      return mergedFilters;
    },
    handleLocationSelected(selection) {
      if (selection.flexible !== undefined) {
        this.store.commit("setSearchIsFlexible", selection.flexible);
      }
      this.store.commit("setfullDateTime", selection.date);

      if(this.createInClub){
        // Construct a new object with the current query parameters
        let currentQueryParams = Object.assign({}, this.$route.query);
        currentQueryParams.date = selection.date;
        currentQueryParams.flexibleTime = selection.flexible;

        setTimeout(() => this.setInitialFakeSearch());
        return this.$router.push({ path: "/search-results", query: currentQueryParams });
      }

      const dataToSend = getSearchDataToSend();
      if (selection.location.length > 0) {
        dataToSend.locName = selection.location[0].name;
      }

      // Aux funtion to convert undefined to null
      const convertUndefinedToNull = (value) => {
        return value !== undefined ? value : null;
      };

      // Mapping all object values  to create url values
      const filteredDataToSend = Object.fromEntries(
        Object.entries(dataToSend).map(([key, value]) => [
          key,
          convertUndefinedToNull(value),
        ])
      );
      this.$router.push({ path: "/search-results", query: filteredDataToSend });
      setTimeout(() => this.setInitialFakeSearch());
    },
    handleTenantSelected(tenant) {
      this.store.commit("setSearchIsFlexible", tenant.flexible);
      this.store.commit("setfullDateTime", tenant.date);
      var loc = getDataToBooking(tenant.tenant);
      this.$router.push(loc);
    },
    async sendMatch(createMatchQuery, isPrivate = false){
      if(isPrivate){
        await this.checkPrivateClub();
      }
      if(this.canCreateMatch){
        this.$router.push(createMatchQuery);
      }
      // start link user
      return this.showPopLinkPrivate = true;
    },
    async hourClicked(data) {
      if (this.createMatchMode) {
        var backUrl = this.$route.fullPath;
        if (this.$route.query.createMatchMode === undefined) {
          backUrl += "&createMatchMode=true";
        }
        this.createMatchQuery = {
          name: "createMatch",
          query: {
            id: data.tenantId,
            name: data.name,
            hour: data.hour,
            date: this.$store.state.fullDateTime,
            sport: this.$route.query.sport,
            category: this.category.MATCH,
            radius: this.$route.query.radius,
            searchType: this.$route.query.searchType,
            back: encodeURIComponent(backUrl),
            levelProvider: this.levelProvider
          },
        };
        this.linkTenantInfo = {
          tenant:{ id: data.tenantId } 
        }
        this.canCreateMatch = await this.initGenderLevel();
        if(!this.canCreateMatch){
          return;
        }
        return this.sendMatch(this.createMatchQuery, data.private);
      }

      var loc = {
        name: "booking",
        query: {
          id: data.tenantId,
          date: this.$route.query.date,
          sport: this.$route.query.sport,
          category: this.$route.query.category,
          hour: data.hour,
        },
      };
      const tenantInfo = this.responseSearchItems.find(
        (y) => y.tenant.id === data.tenantId
      );
      this.store.commit("setTenantInfo", tenantInfo);
      this.$router.push(loc);
    },
    async checkPrivateClub(){
      this.canCreateMatch = await CustomerHelper.customerIsLinked(this.linkTenantInfo.tenant.id);
    },
    tenantClicked(data) {
      if (this.createMatchMode) {
        return;
      }
      var loc = {
        name: "booking",
        query: {
          id: data.tenantId,
          name: data.name,
          date: this.$route.query.date,
          sport: this.$route.query.sport,
          category: this.$route.query.category,
          tab: BookingViewTabs.HOME,
        },
      };
      const tenantInfo = this.responseSearchItems.find(
        (y) => y.tenant.id === data.tenantId
      );
      this.store.commit("setTenantInfo", tenantInfo);

      this.$router.push(loc);
    },
    matchSelected(data) {
      let url = "/booking-join/" + data.id;
      if (data.side !== undefined) {
        url += "?s=" + data.side;
      }
      if (data.team !== undefined) {
        url += "&t=" + data.team;
      }
      this.$router.push(url);
    },
    createMatch(match) {
      this.createMatchMode = true;
      this.selectCategory(Category.BOOKING);
    },
    openSearch() {
      this.$refs.fakeSearchBtn.blur(); // to avoid rare behavior in ios
      this.showMainSearch = true;
    },
    closeSearch() {
      this.showMainSearch = false;
    },
    openFilter() {
      this.showFilter = true;
    },
    closeFilter() {
      this.showFilter = false;
    },
    closeGenderLevel() {
       this.showGenderLevel = false;
       this.sendMatch(this.createMatchQuery);
    },
    async sendObjectFromUrl(url) {
      const queryString = url.substring(url.indexOf("?") + 1);
      let queryData = {};
      // Reconstruct tho original object

      const params = new URLSearchParams(queryString);

      for (const [key, value] of params.entries()) {
        if (Object.prototype.hasOwnProperty.call(queryData, key)) {
          if (Array.isArray(queryData[key])) {
            queryData[key].push(value !== "" ? value : null);
          } else {
            queryData[key] = [queryData[key], value !== "" ? value : null];
          }
        } else {
          queryData[key] = value !== "" ? value : null;
        }
      }

      if (checkURLAvailabilityFormat(queryData)) {
        this.loadData();
      } else {
        this.responseSearchItems = [];
        customGlobalError(this.$t("wrong_url"));
        this.noElementsMsg = this.$t("wrong_url");
      }
    },
    setUpDataFromUrl() {
      const request = {
        date: this.$route.query.date,
        cordX: this.$route.query.cordX,
        cordY: this.$route.query.cordY,
        sport: this.$route.query.sport,
        category: this.$route.query.category,
        radius: this.$route.query.radius,
        searchType: +this.$route.query.searchType,
      };
      
      this.store.commit("setfullDateTime", request.date);
      this.store.commit("setPreferencesPlaces", [
        { cordX: request.cordX, cordY: request.cordY },
      ]);
      this.store.commit("setPreferencesSport", request.sport);
      this.store.commit("setPreferencesAction", request.category);
      this.store.commit("setSearchIsFlexible", request.searchType === SearchType.FLEXIBLE? true : false);
      this.selectedCategory = parseInt(request.category);
    },
    async loadData() {
      this.bookingsDataSource = [];
      this.matchesDataSource = [];
      this.activitiesDataSource = [];
      this.responseSearchItems = [];
      if (this.selectedCategory === this.category.BOOKING) {
        return await this.loadBookings();
      }
      if (this.selectedCategory === this.category.ACTIVITY) {
        return await this.loadActivities();
      }
      return await this.loadMatches();
    },
    async loadActivities(){
      this.store.commit("setLoading", true);
      this.dataToSend = getSearchDataToSend();
      if (!Format.IsNull(this.$route.query.tenantIds)) {
        this.dataToSend.tenantIds = this.$route.query.tenantIds;
        if (!Array.isArray(this.dataToSend.tenantIds)) {
          this.dataToSend.tenantIds = [this.dataToSend.tenantIds];
        }
      }
      if (!Format.IsNull(this.$route.query.id)) {
        this.dataToSend.tenantIds = [this.$route.query.id];
      }
      this.dataToSend.searchType = SearchType.FULL;
  
      const message = await BookingHelper.messageAvailability(this.dataToSend);
      this.store.commit("setLoading", false);
      if (Format.IsNull(message.activities) || message.activities.length === 0) {
        this.noElementsMsg = this.$t("there_are_no_activities_for_the_selected_date_try_another");
        return;
      }
      this.responseSearchItems = message.activities;
      this.noElementsMsg = "";
      this.activitiesDataSource = this.responseSearchItems;
    },
    async loadBookings() {
      this.store.commit("setLoading", true);
      this.dataToSend = getSearchDataToSend();

      if (!Format.IsNull(this.$route.query.tenantIds)) {
        this.dataToSend.tenantIds = this.$route.query.tenantIds;
        if (!Array.isArray(this.dataToSend.tenantIds)) {
          this.dataToSend.tenantIds = [this.dataToSend.tenantIds];
        }
      }
      if (!Format.IsNull(this.$route.query.id)) {
        this.dataToSend.tenantIds = [this.$route.query.id];
      }
      if(this.createInClub){
        this.dataToSend.searchType = SearchType.FULL;
      }
      // If create match mode, set purpose = 1 (find availability to create match)
      if (this.createMatchMode){
        this.dataToSend.purpose=Purpose.MATCH;
      }
      const message = await BookingHelper.messageAvailability(this.dataToSend);
      this.store.commit("setLoading", false);
      if (Format.IsNull(message.bookings) || message.bookings.length === 0) {
        this.noElementsMsg = this.$t("search_no_resources_found");
        return;
      }
      this.noElementsMsg = "";
      this.responseSearchItems = message.bookings;
      this.bookingsDataSource = this.responseSearchItems;

      if(this.bookingsDataSource && this.bookingsDataSource[0].tenant.defaultLevelProviders.length > 0){// if !defaultLevelProviders.length > 0 default level provider always pandora
        this.defaultLevelProviders = this.bookingsDataSource[0].tenant.defaultLevelProviders;
        this.levelProvider = MatchHelper.getLevelProviderBySport(this.defaultLevelProviders, this.sport);
      }
      if (this.isPopup) {
        this.$emit("closePopup");
      }
      this.$emit("searchItems", this.bookingsDataSource);
      this.checkAvailabilityMessage();
      this.fixIphoneHeight();
    },
    async loadMatches() {
      this.store.commit("setLoading", true);
      this.dataToSend = getSearchDataToSend();

      if (!Format.IsNull(this.$route.query.tenantIds)) {
        this.dataToSend.tenantIds = this.$route.query.tenantIds;
        if (!Array.isArray(this.dataToSend.tenantIds)) {
          this.dataToSend.tenantIds = [this.dataToSend.tenantIds];
        }
      }
      if (!Format.IsNull(this.$route.query.id)) {
        this.dataToSend.tenantIds = [this.$route.query.id];
      }
      const message = await BookingHelper.messageAvailability(this.dataToSend);
      this.store.commit("setLoading", false);
      if (Format.IsNull(message.matches) || message.matches.length === 0) {
        this.noElementsMsg = this.$t("search_no_resources_found");
        return;
      }
      this.noElementsMsg = "";
      this.responseSearchItems = message.matches.sort((a, b) => new Date(a.date) - new Date(b.date));
      this.checkAvailabilityMessage();
      this.matchesDataSource = this.responseSearchItems
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const searchReference = document.querySelector(".search-reference");
        if (searchReference) {
          searchReference.classList.add("pb-100");
        }
      }
    },
    sportHasFilters() {
      return this.sport == Sport.PADEL;
    },
    checkAvailabilityMessage(){
      if (this.responseSearchItems && this.responseSearchItems.length === 0) {
        this.noElementsMsg =
          this.$t("search_no_results_found") +
          " " +
          this.$t(getSportLabel(parseInt(this.dataToSend.sport))) +
          " " +
          this.$t("in") +
          " " +
          this.$route.query.locName +
          " " +
          this.$t("with_date") +
          " " +
          beautifyDate(this.dataToSend.date, true, false);
      }
    },
    closePrivateLink(messageOk){
      this.showPopLinkPrivate = false
      if(messageOk){
        this.canCreateMatch = true;
        return this.sendMatch(this.createMatchQuery);
      }
    }
  },
});
</script>
<style scoped>
@media (min-width: 767px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 67px;
    letter-spacing: 0.04em;
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 767px) {
  .tabs li.image {
    background: rgba(255, 255, 255, 0.02);
    opacity: 0.8;
    box-shadow: inset 0px 0px 15px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 30px;
    width: 40px;
    height: 40px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

label {
  margin: 0px 0px 0px 8px;
}
.tabs li:first-child {
  margin-left: 0px;
}

.tabs li:hover {
  color: #ffffff;
}
.tabs li.image:hover svg path {
  fill: #999999;
}
footer {
  width: 90%;
  margin: 20px auto 0px auto;
  padding-bottom: 0px;
}
footer h3 {
  font-size: 48px;
  font-weight: 600;
}
footer h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  display: inline-flex;
  max-width: 140px;
}
footer .open svg {
  position: relative;
  top: -39px;
  left: 145px;
}
.view-courts span {
  position: relative;
  top: 56px;
  left: 42px;
  font: 18px;
  font-weight: 600;
}
.all-tracks {
  width: 184px;
  height: 114px;
  background: #0458e2;
  border-radius: 12px;
}
.all-tracks a {
  position: relative;
  top: 45px;
  left: 40px;
  font: 18px;
  font-weight: 600;
}
input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  border: 1px solid #f3f3f34d;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  outline: none;
}

input[type="checkbox"].selected {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 100px;
}
input[type="checkbox"].selected::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 5px;
  top: 3px;
  width: 4px;
  height: 7px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}
.select-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14.5px 142px;
  width: 100%;
  height: 48px;
  background: linear-gradient(
    103.18deg,
    var(--color1) -38.71%,
    var(--color2) 57.77%
  );
  box-shadow: -2px 4px 18px rgba(0, 15, 53, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  margin-top: 24px;
}
.search-elements::-webkit-scrollbar {
  -webkit-appearance: none;
}

.search-elements {
  max-width: 1340px;
  gap: 16px;
  margin: 0px auto 20px auto;
  max-height: 63vh;
}

.search-image {
  background: linear-gradient(
    265.36deg,
    #0356e1 0.37%,
    #23edee 136.83%
  ) !important;
}
.wrapper-icon-home .container-custom-icon {
  border-radius: 50%;
}
.button-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
.search-elements-container {
  overflow-y: scroll;
}
</style>